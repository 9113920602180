import {default as BSModal} from 'react-bootstrap/Modal';
import {Button} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes} from '@fortawesome/free-solid-svg-icons'

export default function Modal(props: any) {
  const {show, onHide, title, size, centered, enforceFocus} = props

  return (
    <BSModal enforceFocus={enforceFocus} restoreFocus={false} size={size||'md'} centered={centered!==undefined ? centered:true} show={show} onHide={onHide}>
      <BSModal.Body >
        <FontAwesomeIcon icon={faTimes} style={{top: 15, right: 15}} onClick={onHide} className="cursor-pointer position-absolute text-secondary" />
        <h4 className="pb-1 modal__title mt-3 mb-4">{title}</h4>
        {props.children}
      </BSModal.Body>
    </BSModal>
  )
}

export function ConfirmModal(props: any) {
  const {show, onHide, title, content,
    confirmText, cancelText, confirmVariant, onConfirm
  } = props

  return (
    <BSModal restoreFocus={false} centered show={show} onHide={onHide}>
      <BSModal.Body >
        <h3 className="mt-3 mb-4">{title || 'ยืนยันการทำรายการ?'}</h3>
        <p>{content}</p>
        <div className="row-center mt-6">
          <Button onClick={onHide} variant="light">{cancelText || 'ยกเลิก'}</Button>
          <Button onClick={onConfirm} className="ms-5" variant={confirmVariant||'danger'}>{confirmText || 'ยืนยัน'}</Button>
        </div>
      </BSModal.Body>
    </BSModal>
  )
}
