import {useState, useEffect} from 'react'
import { Container, Row, Col, Button, Modal, Carousel, Form } from 'react-bootstrap';
import renter from '../../assets/images/renter.png'
import lessor from '../../assets/images/lessor.png'
import logo from '../../assets/images/logo.png'
import {default as ReactCalendar} from 'react-calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faChevronUp, faMinus, faChevronRight, faChevronLeft, faSquare } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import api from '../../utils/Api'
import { dateRange } from '../../utils/Functions'
import _ from 'lodash'

const dFormat = 'YYYYMMDD'

export default function Calendar(props: any) {
  const {selectRange = true, item, lessorEditing = false, enableDateChange = true, onClickDay, ts} = props
  const [bookings, setBookings] = useState<Array<any>>(props.bookings || [])
  const [calendars, setCalendars] = useState<Array<any>>(props.calendars || [])
  const [form, setForm] = useState<any>({})
  const [currentDate, setCurrentDate] = useState(new Date)
  const [_range, _setRange] = useState<any>(null)
  const u = localStorage.getItem('userKey')
  const [user, setUser] = useState(u?JSON.parse(u):null)

  const fetchCalendar = (dateTime: Date) => {
    api.request('calendar', (res)=>{
      setCalendars(res)
    }, {accommodationId: item.id, userId: item.userId, dateTime})
  }

  const fetchBooking = (dateTime: Date) => {
    api.request('booking', (res)=>{
      setBookings(res)
    }, {accommodationId: item.id, dateTime})
  }

  const getDateTypes = () => {
    const dates: any = {}

    for (let c of calendars) {
      if (c.dateType == 'normal' || !c.dateType) continue;

      dates[moment(c.date).format(dFormat)] = c.dateType==='holiday'?'text-yellow':c.dateType==='vacation'?'text-pink':'text-danger'
    }

    return dates
  }

  const getDisabledDates = () => {
    if (lessorEditing) return []

    const disabledDates = []

    for (let b of bookings) {
      const dates = dateRange(b.startDate, moment(b.endDate).subtract(1, 'day'))
      for (let date of dates) {
        disabledDates.push(date.format(dFormat))
      }
    }

    for (let c of calendars) {
      if (['booked', 'closed'].indexOf(c.bookingCondition) === -1) continue;

      disabledDates.push(moment(c.date).format(dFormat))
    }


    return disabledDates
  }

  const findMaxDate = () => {
    let maxDate = null

    for (let b of bookings) {
      if (moment(b.startDate).format(dFormat) > form.startDate.format(dFormat)) {
        maxDate = moment(b.startDate)
        break;
      }
    }

    for (let c of calendars) {
      if (['booked', 'closed'].indexOf(c.bookingCondition) === -1) continue;

      if (moment(c.date).format(dFormat) > form.startDate.format(dFormat) &&
      (!maxDate || (maxDate.format(dFormat)>moment(c.date).format(dFormat)))
      ) {
        maxDate = moment(c.date)
        break;
      }
    }

    return maxDate?maxDate.toDate():undefined
  }

  const findDateType = (_date: any) => {
    if (!_date) return null

    const c = _.find(calendars, ({date})=> (moment(date).format(dFormat) === moment(_date).format(dFormat)))
    if (c) return c

    return {dateType: 'normal'}
  }

  const findDateCondition = (date: any): string|null => {
    if (!date) return null
    const currentDateString = date.format(dFormat)
    if (_.find(bookings, ({startDate, endDate})=>{
      return moment(startDate).format(dFormat)<=currentDateString &&
        moment(endDate).format(dFormat)>currentDateString
    })) {
      return 'booking'
    } else if (_.find(calendars, ({date, bookingCondition})=>{
      return moment(date).format(dFormat) === currentDateString && bookingCondition === 'booked'
    })) {
      return 'booked'
    } else if (_.find(calendars, ({date, bookingCondition})=>{
      return moment(date).format(dFormat) === currentDateString && bookingCondition === 'two_nights'
    })) {
      return 'two_nights'
    } else if (_.find(calendars, ({date, bookingCondition})=>{
      return moment(date).format(dFormat) === currentDateString && bookingCondition === 'closed'
    })) {
      return 'closed'
    }

    return null
  }

  const _renderDateCondition = (date: any) => {
    let icon:any = null
    let style = {}

    const condition = findDateCondition(date)
    if (condition === 'booking' || condition === 'booked') {
      icon = faTimes
    } else if (condition === 'two_nights') {
      icon = faChevronUp
      style = {marginTop: -20}
    } else if (condition === 'closed') {
      icon = faMinus
    }

    return  <div className="absolute-fill row-center">
      <FontAwesomeIcon icon={icon}
      className="text-secondary fs-4"
      style={{ opacity: 0.75, ...style }}
       />
      </div>
  }

  const cancel = () => {
    const newForm = {
      startDate: null,
      endDate: null
    }
    _setRange(null)
    setForm(newForm)
    props.onCancel?.();
  }

  const submit = () => {
    props.onSubmit?.(form);
    _setRange(null)
    setForm({
      startDate: null,
      endDate: null
    })
  }

  useEffect(()=>{
    if (!item) return
    fetchCalendar(currentDate)
    fetchBooking(currentDate)
  }, [currentDate, ts])

  const selectingEndDate = !!form.startDate && !form.endDate
  const dateTypes = getDateTypes()
  const disabledDates = getDisabledDates()

  return (
  <div>
    <ReactCalendar
      selectRange={enableDateChange?selectRange:false}
      minDate={selectingEndDate?moment(form.startDate).add(1, 'day').toDate():new Date()}
      maxDate={selectingEndDate?findMaxDate():undefined}
      locale="th"
      formatShortWeekday={(locale, date)=>moment(date).format('ddd')}
      tileContent={({ date }) => selectingEndDate?null:_renderDateCondition(moment(date))}
      tileClassName={({date})=>dateTypes[moment(date).format(dFormat)] || ''}
      tileDisabled={({date})=>!selectingEndDate && disabledDates.indexOf(moment(date).format(dFormat)) > -1}
      onActiveStartDateChange={({activeStartDate})=>{
        if (activeStartDate) setCurrentDate(activeStartDate)
      }}
      onChange={(d)=>{
        if (!enableDateChange) return
        _setRange(d)
      }}
      value={_range}
      nextLabel={<div><FontAwesomeIcon className="text-primary" icon={faChevronRight} /></div>}
      prevLabel={<div><FontAwesomeIcon className="text-primary" icon={faChevronLeft} /></div>}
      onClickDay={(v: any)=>{
        if (onClickDay) {
          v = moment(v)
          onClickDay(v, findDateType(v), findDateCondition(v))
          return
        }
        if (!enableDateChange) return

        const newForm = {...form}

        if (newForm.startDate) {
          if (!newForm.endDate)  {
            newForm.endDate = moment(v)
          } else {
            newForm.startDate = moment(v)
            newForm.endDate = null
          }
        } else {
          newForm.startDate = moment(v)
          newForm.endDate = null
        }

        setForm(newForm)
      }}
      calendarType="US"
    />
    <div style={{marginLeft: '2%'}} className="d-flex my-2">
      <div style={{width: '32%'}}><FontAwesomeIcon className="me-2" icon={faTimes} />ติดจอง</div>
      <div style={{width: '33%'}}><FontAwesomeIcon className="me-2" icon={faMinus} />ไม่เปิดจอง/ปรับปรุง</div>
      <div style={{width: '32%'}}><FontAwesomeIcon className="me-2" icon={faChevronUp} />จอง 2 คืนขึ้นไป</div>
    </div>
    <div style={{marginLeft: '2%'}} className="d-flex">
      <div style={{width: '32%'}}><FontAwesomeIcon className="me-2 text-danger" icon={faSquare} />ราคาพิเศษ</div>
      <div style={{width: '33%'}}><FontAwesomeIcon className="me-2 text-yellow" icon={faSquare} />วันหยุดนักขัตฤกษ์</div>
      <div style={{width: '32%'}}><FontAwesomeIcon className="me-2 text-pink" icon={faSquare} />วันหยุดยาว</div>
    </div>
    { form.startDate && form.endDate &&
    <div className="column-center mt-3">
      <Button disabled={!user && item} onClick={()=>submit()}>{item && !user ?'กรุณาเข้าสู่ระบบก่อนทำการจอง':`ยืนยันการเลือก (${moment(form.endDate).diff(form.startDate, 'day')} คืน)`}</Button>
      <span onClick={()=>cancel()} className="link text-muted mt-2">ยกเลิก</span>
    </div>
    }
  </div>
  )
}
