import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Badge } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'
import {Layout} from '../components'
import {AccommodationItem} from '../components/app/Accommodation'
import {LessorFeed, RenterFeed} from '../components/app/Feed'
import api from '../utils/Api'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import _ from 'lodash'

export default function Feed(props: any) {

  const {hash} = useLocation()
  const [paginate, setPaginate] = useState({data: [], page: 1, meta: {total: null}})
  const [tab, setTab] = useState(hash.replace('#','') || 'lessor')
  const [filterCount, setFilterCount] = useState<number>(0)

  const fetch = (params = {}) => {
    api.request('accommodation', (r: any)=>{
      setPaginate(r)
    }, params)
  }
  useEffect(()=>{
    fetch()
  }, [])

  useEffect(()=>{
    setTab(hash.replace('#','')||'lessor')
  }, [hash])

  const { data, meta } = paginate

  return (
    <Layout>
      <TabContext value={tab}>
        <div className="feed feed__tab">
          <TabList onChange={(e, v)=>setTab(v)}>
            <Tab value={'lessor'} label="ประกาศหาผู้เช่า"  />
            <Tab value={'renter'} label="ประกาศหาที่พัก"  />
          </TabList>
        </div>
        <Container>
          <TabPanel value="lessor">
            <LessorFeed />
          </TabPanel>
          <TabPanel value="renter">
            <RenterFeed />
          </TabPanel>
        </Container>
      </TabContext>
    </Layout>
  )
}
