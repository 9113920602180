import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Form, Card, Nav } from 'react-bootstrap';
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import {Layout, NoResult, CheckboxHighlight, Modal} from '../../components'
import {AccommodationItem} from '../../components/app/Accommodation'
import {CouponItem} from '../../components/app/Coupon'
import api from '../../utils/Api'
import { inputOnlyNumber } from '../../utils/Helpers'
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faCalendarDay, faMessage, faList, faTrash, faBan, faCheck,
  faEdit, faTimes, faChevronUp, faMinus} from '@fortawesome/free-solid-svg-icons'
import {LessorWithMenu} from '../../components/app/LessorMenu'
import {getRemainingDays} from '../MyAccommodation'
import Calendar from '../../components/app/Calendar'
import duck from '../../assets/images/duck.png'
import { toast } from 'react-toastify';
import moment from 'moment'

const dateTypes = [{
  value: 'normal',
  label: 'วันปกติ(จะเปลี่ยนทุกหลัง)',
  color: 'light'
},{
  value: 'holiday',
  label: 'วันหยุดนักขัตฤษ์(จะเปลี่ยนทุกหลัง)',
  color: 'warning'
},{
  value: 'vacation',
  label: 'วันหยุดยาว(จะเปลี่ยนทุกหลัง)',
  color: 'pink'
},{
  value: 'discount',
  label: 'ลดราคา(จะเปลี่ยนทุกหลัง)',
  color: 'danger'
}]

const bookingConditions = [{
  value: 'free',
  label: 'ว่าง',
  icon: null
},{
  value: 'two_nights',
  label: 'ว่าง(จอง 2 คืนขึ้นไป)',
  icon: faChevronUp
},{
  value: 'closed',
  label: 'ไม่เปิดจอง/ปรับปรุง',
  icon: faMinus
},{
  value: 'booked',
  label: 'ติดจอง',
  icon: faTimes
}]

const approvalStates = [{
  value: null,
  label: 'รอการตรวจสอบ',
  color: 'warning'
},{
  value: true,
  label: 'อนุมัติ',
  color: 'success'
},{
  value: false,
  label: 'ไม่อนุมัติ',
  color: 'danger'
},]

export default function ManageAccommodation() {
  const  {id} = useParams()
  const navigate = useNavigate()
  const {hash} = useLocation()
  const [form, setForm] = useState<any>({id})
  const [rank, setRank] = useState('-')
  const [modal, setModal] = useState<string|null>(null)
  const [post, setPost] = useState<any>(null)
  const [day, setDay] = useState(0)
  const [selection, setSelection] = useState<any>({date: null, condition: null, type: null, discountPercent: ''})
  const [ts, setTs] = useState(new Date)

  const fetchPost = () => {
    api.request(`accommodation/${id}/post`, (r)=>{
      setPost(r)
    })
  }

  const fetchAccommodation = (init=false) => {
    api.request(`accommodation/${id}`, (r)=>{
      setForm(r)
      if (hash && init) {
        document.querySelector(hash)?.scrollIntoView({
          behavior: 'auto'
        });
      }
    })
  }

  const fetchRank = () => {
    api.request(`accommodation/${id}/rank`, ({rank})=>{
      setRank(rank)
    })
  }


  useEffect(()=>{
    let html = document.querySelector('html');
    if (html) {
      html.id = 'instant-scroll'
    }

    fetchPost()
    fetchAccommodation(true)
    fetchRank()
  }, [])

  const disabledActions = (!form.publishExpiredAt && !form.specialPriceExpiredAt && !form.recommendedExpiredAt) || !form.isApproved
  const approvalState = _.find(approvalStates, ['value', form.isApproved])

  if (!form.userId) {
    return null
  }

  return (
    <Layout>
      <Container className="my-3">
        <Row>
          <Col xs={12} md={6} lg={5}>
            <AccommodationItem item={form} />
          </Col>
          <Col xs={12} md={6} lg={7}>
            <div className="card-item p-3">
              <div className="row-edge-center mb-2">
                <span>สถานะ</span>
                <span className={"text-"+(form.isTemporarilyClosed?'danger':'success')}>{form.isTemporarilyClosed?'ปิดใช้งาน':'เปิดใช้งาน'}</span>
              </div>
              <div className="row-edge-center mb-2">
                <span>การตรวจสอบโดย Admin</span>
                <span className={"text-"+(approvalState?.color||'warning')}>{approvalState?.label}</span>
              </div>
              <div className="row-edge-center mb-2">
                <span>อายุโพสต์คงเหลือ</span>
                <span>{getRemainingDays(form.publishExpiredAt)}</span>
              </div>
              <div className="row-edge-center mb-2">
                <span>วันติดป้ายราคาพิเศษคงเหลือ</span>
                <span>{getRemainingDays(form.specialPriceExpiredAt)}</span>
              </div>
              <div className="row-edge-center mb-2">
                <span>วันติดป้ายแนะนำคงเหลือ</span>
                <span>{getRemainingDays(form.recommendedExpiredAt)}</span>
              </div>
              <div className="row-edge-center mb-2">
                <span>ปัจจุบันโพสต์บ้านหลังนี้อยู่ลำดับที่</span>
                <span>ลำดับที่ {rank||'-'}</span>
              </div>
              <div className="row-edge-center mb-2">
                <span>ยืนยันการจองสำเร็จ</span>
                <span>{form.totalConfirmedBookings||'-'} ครั้ง</span>
              </div>
            </div>
            <Row className="mt-3">
              <Col xs={6} sm={6} xl={3} className="mb-2">
                <Button className="w-100" disabled={!form.isApproved} onClick={()=>setModal('extendingPost')} >ต่ออายุโพสต์</Button>
              </Col>
              <Col xs={6} sm={6} xl={3} className="mb-2">
                <Button className="w-100 text-light" disabled={!form.isApproved} onClick={()=>setModal('specialPrice')}
                variant={'warning'}>ติดป้ายราคาพิเศษ</Button>
              </Col>
              <Col xs={6} sm={6} xl={3} className="mb-2">
                <Button className="w-100" disabled={!form.isApproved} onClick={()=>setModal('recommended')}
                variant={'success'}>ติดป้ายแนะนำ</Button>
              </Col>
              <Col xs={6} sm={6} xl={3} className="mb-2">
                <Button className="w-100 bg-yellow border-yellow text-dark" disabled={disabledActions}
                variant={'warning'}
                onClick={()=>setModal('priority')}>เลื่อนโพสต์</Button>
              </Col>
              <Col xs={12}>
                <Button onClick={()=>{
                  const url = post ? `post/${post.id}` : 'post'
                  const method = post ? 'DELETE' : 'POST'
                  api.request(url, (r)=>{
                    setPost(r && r.id ? r : null);
                    toast.success(
                     r?.id ? 'โพสต์ในหน้าฟีดสำเร็จ' : 'ลบบ้านพักจากหน้าฟีดแล้ว'
                    );
                  }, api.form({accommodationId: form.id}), method)
                }}  disabled={disabledActions} variant="info" className="w-100">
                  <FontAwesomeIcon icon={post?faTimes:faList} className="me-3" />{post?'ลบบ้านพักออกจากหน้าฟีด':'โพสต์บ้านพักนี้ในหน้าฟีด'}
                </Button>
              </Col>
              <Col xs={12} className="mt-2">
                <div className="card-item d-flex w-100 p-2">
                  <div onClick={()=>{
                    if (!window.confirm('คุณแน่ใจว่าต้องการลบที่พักนี้ ?')) return

                    api.request(`accommodation/${form.id}`, ()=>{
                      navigate('/my-accommodation')
                    }, api.form({isDeleted: true}), 'PATCH')
                  }} className="manage-accommodation__tool-container text-danger">
                    <FontAwesomeIcon icon={faTrash} />
                    <span className="mt-2">ลบโพสต์นี้</span>
                  </div>
                  <div onClick={()=>{
                    api.request(`accommodation/${form.id}`, ()=>{
                      fetchAccommodation()
                    }, api.form({isTemporarilyClosed: !form.isTemporarilyClosed}), 'PATCH')
                  }} className="manage-accommodation__tool-container text-info">
                    <FontAwesomeIcon icon={form.isTemporarilyClosed?faCheck:faBan} />
                    <span className="mt-2 text-center">{form.isTemporarilyClosed?'เปิดจอง':'ปิดจองชั่วคราว'}</span>
                  </div>
                  <div className="manage-accommodation__tool-container text-info">
                    <FontAwesomeIcon icon={faEdit} />
                    <span className="mt-2 text-center">แก้ไขที่พัก</span>
                  </div>
                  <div onClick={()=>navigate('/accommodation/'+form.id)} className="manage-accommodation__tool-container text-info">
                    <FontAwesomeIcon icon={faList} />
                    <span className="mt-2 text-center">ดูตัวอย่างโพสต์</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} id="manage-date" className="mt-3">
            <div className="card-item p-3">
              <Calendar
                enableDateChange={false}
                item={form}
                lessorEditing
                onClickDay={(date: any, type: {dateType: string, discountPercent?: string}, condition: string)=>{
                  setSelection({date, type: type.dateType, condition, discountPercent: type.discountPercent})
                  setModal('editDateOption')
                }}
                ts={ts}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Modal size="sm" title={"ต่ออายุโพสต์"} onHide={()=>setModal(null)} show={modal === 'extendingPost'}>
        <CheckboxHighlight
          className="mb-2"
          onCheck={()=>setDay(30)}
          checked={day===30}
          label={<div>
            <small>ต่ออายุโพสต์ <small className="text-info">30</small> วัน ใช้
            {" "}<small className="text-warning">30</small>{" "}<img src={duck} style={{width: 14, height: 14}} /></small>
            <small className="text-secondary ms-2">เฉลี่ยวันละ 1 บาท</small>
            </div>}
        />
        <CheckboxHighlight
          className="mb-2"
          onCheck={()=>setDay(60)}
          checked={day===60}
          label={<div>
            <small>ต่ออายุโพสต์ <small className="text-info">60</small> วัน ใช้
            {" "}<small className="text-warning">60</small>{" "}<img src={duck} style={{width: 14, height: 14}} /></small>
            <small className="text-secondary ms-2">เฉลี่ยวันละ 1 บาท</small>
            </div>}
        />
        <div className="row-center mt-3">
          <Button onClick={()=>setModal(null)} variant="secondary">ยกเลิก</Button>
          <Button onClick={()=>{
            setModal(null)
            api.request(`accommodation/${id}/purchase`, (r, s)=>{
              if (!s) return
              toast.success('ต่ออายุโพสต์สำเร็จ')
              fetchAccommodation()
            }, api.form({action: 'publish', days: day}), 'PATCH')
          }} className="ms-2">ยืนยัน</Button>
        </div>
      </Modal>
      <Modal size="sm" title={"ติดป้ายราคาพิเศษ"} onHide={()=>setModal(null)} show={modal === 'specialPrice'}>
        <CheckboxHighlight
          className="mb-2"
          onCheck={()=>setDay(7)}
          checked={day===7}
          label={<div>
            <small>ติดป้ายราคาพิเศษ <small className="text-info">7</small> วัน ใช้
            {" "}<small className="text-warning">7</small>{" "}<img src={duck} style={{width: 14, height: 14}} /></small>
            <small className="text-secondary ms-2">เฉลี่ยวันละ 1 บาท</small>
            </div>}
        />
        <CheckboxHighlight
          className="mb-2"
          onCheck={()=>setDay(14)}
          checked={day===14}
          label={<div>
            <small>ติดป้ายราคาพิเศษ <small className="text-info">14</small> วัน ใช้
            {" "}<small className="text-warning">14</small>{" "}<img src={duck} style={{width: 14, height: 14}} /></small>
            <small className="text-secondary ms-2">เฉลี่ยวันละ 1 บาท</small>
            </div>}
        />
        <div className="row-center mt-3">
          <Button onClick={()=>setModal(null)} variant="secondary">ยกเลิก</Button>
          <Button onClick={()=>{
            setModal(null)
            api.request(`accommodation/${id}/purchase`, (r, s)=>{
              if (!s) return
              toast.success('โพสต์นี้ถูกติดป้ายราคาพิเศษแล้ว')
              fetchAccommodation()
            }, api.form({action: 'special_price', days: day}), 'PATCH')
          }} className="ms-2">ยืนยัน</Button>
        </div>
      </Modal>
      <Modal size="sm" title={"ติดป้ายแนะนำ"} onHide={()=>setModal(null)} show={modal === 'recommended'}>
        <CheckboxHighlight
          className="mb-2"
          onCheck={()=>setDay(30)}
          checked={day===30}
          label={<div>
            <small>ติดป้ายแนะนำ(พร้อมอายุโพสต์) <small className="text-info">30</small> วัน ใช้
            {" "}<small className="text-warning">30</small>{" "}<img src={duck} style={{width: 14, height: 14}} /></small>
            <small className="text-secondary ms-2">เฉลี่ยวันละ 1 บาท</small>
            </div>}
        />
        <CheckboxHighlight
          className="mb-2"
          onCheck={()=>setDay(60)}
          checked={day===60}
          label={<div>
            <small>ติดป้ายแนะนำ(พร้อมอายุโพสต์) <small className="text-info">60</small> วัน ใช้
            {" "}<small className="text-warning">60</small>{" "}<img src={duck} style={{width: 14, height: 14}} /></small>
            <small className="text-secondary ms-2">เฉลี่ยวันละ 1 บาท</small>
            </div>}
        />
        <div className="row-center mt-3">
          <Button onClick={()=>setModal(null)} variant="secondary">ยกเลิก</Button>
          <Button onClick={()=>{
            setModal(null)
            api.request(`accommodation/${id}/purchase`, (r, s)=>{
              if (!s) return
              toast.success('โพสต์นี้ถูกติดป้ายแนะนำแล้ว')
              fetchAccommodation()
            }, api.form({action: 'recommended', days: day}), 'PATCH')
          }} className="ms-2">ยืนยัน</Button>
        </div>
      </Modal>
      <Modal size="sm" title={"เลื่อนโพสต์"} onHide={()=>setModal(null)} show={modal === 'priority'}>
        <div>
            <small>การเลื่อนอันดับโพสต์ใช้
            {" "}<small className="text-warning">10</small>{" "}<img src={duck} style={{width: 14, height: 14}} /></small>
        </div>

        <div className="row-center mt-3">
          <Button onClick={()=>setModal(null)} variant="secondary">ยกเลิก</Button>
          <Button onClick={()=>{
            setModal(null)
            api.request(`accommodation/${id}/purchase`, ({rank}, s)=>{
              if (!s) return
              toast.success(`โพสต์บ้านพักนี้ถูกเลื่อนขึ้นไปเป็นอันดับที่ ${rank} แล้ว`)
              fetchAccommodation()
            }, api.form({action: 'priority', days: day}), 'PATCH')
          }} className="ms-2">ยืนยัน</Button>
        </div>
      </Modal>
      <Modal
       title={'คุณกำลังเลือกวันที่ ' + moment(selection?.date).format('DD/MM/YY')}
       onHide={()=>setModal(null)}
       show={modal==='editDateOption'}>
       {selection.condition === 'booking' &&
       <small className="text-secondary">*ผู้เช่าได้ทำรายการจองวันนี้แล้ว จะไม่สามารถแก้ไขวัน/การจองได้</small>
       }
        <div className="d-flex">
          <div onClick={()=>{
            if (selection.condition === 'booking') return
            setModal('dateType')
          }} className="manage-accommodation__tool-container text-info bg-white border-primary border">
            <FontAwesomeIcon icon={faCalendarDay} />
            <span className="mt-2 text-center">แก้ไขวัน</span>
          </div>
          <div onClick={()=>{
            if (selection.condition === 'booking') return
            setModal('bookingCondition')
          }} className="manage-accommodation__tool-container text-info bg-white border-primary border">
            <FontAwesomeIcon icon={faEdit} />
            <span className="mt-2 text-center">แก้ไขการจอง</span>
          </div>
          <div className="manage-accommodation__tool-container text-info bg-white border-primary border">
            <FontAwesomeIcon icon={faMessage} />
            <span className="mt-2 text-center">ติดต่อผู้เช่า</span>
          </div>
        </div>
      </Modal>
      <Modal size="sm" title={"แก้ไขวัน "+ moment(selection?.date).format('DD/MM/YY')}
      onHide={()=>setModal(null)} show={modal === 'dateType'}>
        { dateTypes.map(({label, value, color}, index)=>(
          <CheckboxHighlight
            className="mb-2"
            onCheck={()=>setSelection({...selection, type: value})}
            checked={value===selection.type || (value==='normal' && !selection.type)}
            label={<div>
              <FontAwesomeIcon icon={faSquare} className={"text-"+color} />
              <small className="ms-2">{label}</small>
              </div>}
          />
        ))
        }
        {  selection.type === 'discount' &&
          <div className="row-vcenter mt-3">
            ส่วนลด<Form.Control value={selection.discountPercent} onChange={(e)=>setSelection({...selection, discountPercent: e.target.value})}
            type="number" min={1} max={100} size="sm" placeholder={'กรอกส่วนลด %'} className="mx-3" onKeyDown={inputOnlyNumber} />%
          </div>
        }
        <div className="row-center mt-3">
          <Button onClick={()=>setModal(null)} variant="secondary">ยกเลิก</Button>
          <Button onClick={()=>{
            api.request('calendar', (res, s)=>{
              if (!s) return

              setTs(new Date)
              setModal(null)
              toast.success('บันทึกสำเร็จ');
            }, api.form({
              dateType: selection.type,
              date: selection.date,
              discountPercent: selection.discountPercent
            }), 'POST')
          }} className="ms-2">ยืนยัน</Button>
        </div>
      </Modal>
      <Modal size="sm" title={"แก้ไขการจอง "+ moment(selection?.date).format('DD/MM/YY')}
      onHide={()=>setModal(null)} show={modal === 'bookingCondition'}>
        { bookingConditions.map(({label, value, icon}: any, index)=>(
          <CheckboxHighlight
            className="mb-2"
            onCheck={()=>setSelection({...selection, condition: value})}
            checked={value===selection.condition}
            label={<div className="row-vcenter">
              <div style={{width: 20, height: 25}} className="row-center bg-light rounded">
                <FontAwesomeIcon icon={icon} />
              </div>
              <small className="ms-2">{label}</small>
              </div>}
          />
        ))
        }
        <div className="row-center mt-3">
          <Button onClick={()=>setModal(null)} variant="secondary">ยกเลิก</Button>
          <Button onClick={()=>{
            api.request('calendar', (res, s)=>{
              if (!s) return

              setTs(new Date)
              setModal(null)
              toast.success('บันทึกสำเร็จ');
            }, api.form({
              bookingCondition: selection.condition,
              date: selection.date,
              accommodationId: id,
            }), 'POST')
          }} className="ms-2">ยืนยัน</Button>
        </div>
      </Modal>
    </Layout>
  )
}
