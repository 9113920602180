import {useState, useEffect} from 'react'
import { Container, Row, Col, Button, Modal, Carousel, Form } from 'react-bootstrap';
import renter from '../../assets/images/renter.png'
import lessor from '../../assets/images/lessor.png'
import logo from '../../assets/images/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import api from '../../utils/Api'
import _ from 'lodash'
import moment from 'moment'

const years = _.range(parseInt(moment().subtract(100, 'years').format('YYYY')),parseInt(moment().format('YYYY'))).map((y)=>({
  label: y.toString(), value: y
})).reverse()

const months = _.range(1,13).map((y)=>({
  label: moment(y, 'M').format('MMMM'), value: y
}))

const genderOptions = [
  {
    label: 'ชาย',
    value: 'male'
  },
  {
    label: 'หญิง',
    value: 'female'
  },
  {
    label: 'LGBTQ+',
    value: 'lgbt'
  },
  {
    label: 'ไม่ระบุ',
    value: 'none'
  },
]

export default function RegisterModal(props: any) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [isLessor, setIsLessor] = useState(false)
  const [form, setForm] = useState<any>({gender: null, birthYear: null, mobile: null,
    firstName: '',
    lastName: '',
    password: '',
    email: ''})

  const requestOtp = (mobile: string) => {
    api.request('otp/send', (r)=>{
      toast.info(`OTP ถูกส่งไปยังเบอร์ ${mobile} แล้ว`)
    }, api.form({mobile}), 'POST')
  }

  useEffect(()=>{
    if (props.show) return

    setTimeout(()=>{
      setActiveIndex(0)
      setForm({
        gender: null, birthYear: null, mobile: null,
        firstName: '',
        lastName: '',
        password: '',
        email: ''})
    }, 300)
  }, [props.show])

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={props.show}
      onHide={props.onHide}
    >
      <Modal.Header closeButton />
      <Modal.Body className="register">
        <Carousel
        slide={false}
        interval={null}
        keyboard={false}
        controls={false}
        touch={false}
        indicators={false}
        activeIndex={activeIndex}
        >
          <Carousel.Item>
            <div className="column-center mb-3">
              <img src={logo} className="register__logo" />
              <div className="mt-2">โปรดเลือกประเภทสมาชิก</div>
            </div>
            <div onClick={()=>{
              setIsLessor(false)
              setForm({...form, type: 'renter'})
              setActiveIndex(1)
            }} className="register__selector shadow">
              <div>
                <img src={renter} />
                <strong>ผู้เช่า หรือ นายหน้า</strong>
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
            <div onClick={()=>{
              setIsLessor(true)
              setForm({...form, type: 'lessor'})
              setActiveIndex(1)
            }} className="register__selector shadow">
              <div>
                <img src={lessor} />
                <strong>เจ้าของที่พัก หรือ ตัวแทน</strong>
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </Carousel.Item>
          <Carousel.Item className="px-3">
            <div className="column-center mb-3">
              <img src={logo} className="register__logo" />
              <div className="mt-2">สมัครสมาชิก</div>
              <small>สำหรับ{isLessor?'เจ้าของที่พัก หรือ ตัวแทน':'ผู้เช่า หรือ นายหน้า'}</small>
            </div>
            <div className="d-flex">
              <Form.Control
               onChange={(e)=>setForm({...form, firstName: e.target.value})} placeholder={'ชื่อ'} />
              <Form.Control
              className="ms-2"
               onChange={(e)=>setForm({...form, lastName: e.target.value})} placeholder={'สกุล'} />
            </div>
            { isLessor &&
            <>
            <Form.Control
              className="mt-2"
             onChange={(e)=>setForm({...form, projectName: e.target.value})} placeholder={'ชื่อโครงการ/รีสอร์ท'} />
            <Form.Control
             className="mt-2"
             onChange={(e)=>setForm({...form, invitationCode: e.target.value})} placeholder={'Invite Code ที่ได้จากแอดมิน'} />
           </>
            }
            { !isLessor &&
            <>
            <div className="mt-3">
              <strong>ระบุเพศ</strong>
              <Row>
                { genderOptions.map(({label, value}, index)=>(
                  <Col xs={3} key={index} className="mt-2">
                  <Form.Check
                      type={'radio'}
                      id={`gender-${index}`}
                      onChange={(e)=>{
                        const checked = e.target.checked
                        setForm({...form,
                           gender: value
                         })
                      }}
                      label={label}
                      checked={form.gender===value}
                    />
                  </Col>
                ))
               }
              </Row>
            </div>
            <div className="d-flex mt-3">
              <Form.Control onChange={(e)=>setForm({...form, birthMonth: e.target.value})} as="select">
                <option value="">เลือกเดือนเกิด</option>
              {
                months.map(({label, value}, index)=>(
                  <option value={value}>{label}</option>
                ))
              }
              </Form.Control>
              <Form.Control onChange={(e)=>setForm({...form, birthYear: e.target.value})} className="ms-2" as="select">
                <option value="">เลือกปีเกิด</option>
              {
                years.map(({label, value}, index)=>(
                  <option value={value}>{label}</option>
                ))
              }
              </Form.Control>
            </div>
            </>
            }
            <div className="mt-3">
              <strong>สำหรับให้{isLessor?'ผู้เช่า':'เจ้าของที่พัก'}ติดต่อ</strong>
              <Form.Control
                className="mt-2"
                maxLength={10}
                id="mobile"
                name="mobile"
               onChange={(e)=>setForm({...form, mobile: e.target.value})} placeholder={'เบอร์โทร'} />
              <Form.Control
                id="contact_line"
                name="contact_line"
                className="mt-2"
               onChange={(e)=>setForm({...form, contact_line: e.target.value})} placeholder={'LINE ID'} />
              <Form.Control type="email"
                id="email"
                name="email"
                className="mt-2"
               onChange={(e)=>setForm({...form, email: e.target.value})} placeholder={'อีเมล'} />
            </div>
            <Button onClick={()=>{
              if (!form.mobile) {
                toast.warn('กรุณากรอกเบอร์โทร')
                return
              }
              api.request('register/check', (r, s)=>{
                if (s) {
                  if (isLessor) {
                    requestOtp(form.mobile)
                  }
                  setActiveIndex(2)
                }
              }, api.form(form), 'POST')

            }} className="w-100 mt-3">ดำเนินการต่อ</Button>
            <div className="row-center mt-1 cursor-pointer" onClick={()=>setActiveIndex(0)}>
              <FontAwesomeIcon icon={faArrowLeft} /><span className="ms-2">ย้อนกลับ</span>
            </div>
          </Carousel.Item>
          <Carousel.Item className="px-3">
            <div className="column-center mb-3">
              <img src={logo} className="register__logo" />
              <div className="mt-2">{isLessor?'กรอก OTP':'ตั้งรหัสผ่าน'}</div>
              <small>สำหรับ{isLessor?'เจ้าของที่พัก หรือ ตัวแทน':'ผู้เช่า หรือ นายหน้า'}</small>
            </div>
            <Form.Control
             type="password"
             onChange={(e)=>setForm({...form, password: e.target.value})}
             placeholder={isLessor?'กรอก OTP ที่ได้จากรับ SMS':'กรอกรหัสผ่านที่ท่านต้องการ'} />
            <Button onClick={()=>{
              if (!form.password) {
                toast.warn('กรุณากรอกรหัส')
                return
              }

              api.request('register', ({user, token}, s)=>{
                if (!s) return
                localStorage.setItem('userKey', JSON.stringify(user))
                localStorage.setItem('tokenKey', token.token)
                if (props.onLogin) {
                  props.onLogin(user, token)
                }
                toast.success('สมัครสมาชิกสำเร็จ!')
                setTimeout(()=>{
                  window.location.reload()
                }, 1000)
              }, api.form({...form, name:`${form.firstName} ${form.lastName}`, otp: form.password, type: isLessor?'lessor':'renter'}), 'POST')
            }} className="w-100 mt-3">สมัครสมาชิก</Button>
            <div className="row-center mt-1 cursor-pointer" onClick={()=>setActiveIndex(1)}>
              <FontAwesomeIcon icon={faArrowLeft} /><span className="ms-2">ย้อนกลับ</span>
            </div>
          </Carousel.Item>
        </Carousel>
      </Modal.Body>
    </Modal>
  )
}
