import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";

import Home from './screens/Home'
import Accommodation from './screens/Accommodation'
import Feed from './screens/Feed'
import CreditPurchase from './screens/credit/CreditPurchase'
import BookingList from './screens/BookingList'
import CreateAccommodation from './screens/CreateAccommodation'
import MyAccommodation from './screens/MyAccommodation'
import ManageBooking from './screens/ManageBooking'
import MyCoupon from './screens/MyCoupon'
import Favorite from './screens/Favorite'
import ManageAccommodation from './screens/myAccommodation/ManageAccommodation'
import Chat from './screens/Chat'

import './App.scss';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/feed" element={<Feed />} />
        <Route path="/manage-booking" element={<ManageBooking />} />
        <Route path="/booking/:state" element={<BookingList />} />

        <Route path="/accommodation/create" element={<CreateAccommodation />} />
        <Route path="/accommodation/:id" element={<Accommodation />} />

        <Route path="/credit/purchase" element={<CreditPurchase />} />

        <Route path="/my-accommodation" element={<MyAccommodation />} />
        <Route path="/my-accommodation/:id" element={<ManageAccommodation />} />

        <Route path="/coupon" element={<MyCoupon />} />
        <Route path="/favorite" element={<Favorite />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/chat/:id" element={<Chat />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
