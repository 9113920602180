import {useState, useEffect, useRef} from 'react'
import { Container, Row, Col, Button, Modal, Carousel, Form, Spinner } from 'react-bootstrap';
import renter from '../../../assets/images/renter.png'
import lessor from '../../../assets/images/lessor.png'
import logo from '../../../assets/images/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faArrowLeft, faQrcode } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import api from '../../../utils/Api'
import thaiQrIcon from '../../../assets/images/thai-qr.png'
import creditCardIcon from '../../../assets/images/credit-card.png'
import Cards from 'react-credit-cards';

import Payment from "payment";

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value: string) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value: string) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  // if (allValues.number) {
  //   const issuer = Payment.fns.cardType(allValues.number);
  //   maxLength = issuer === "amex" ? 4 : 3;
  // }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value: string) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatFormData(data: any) {
  return Object.keys(data).map(d => `${d}: ${data[d]}`);
}


export default function PaymentModal(props: any) {
  const {item, onFinish} = props
  const formRef = useRef<any>(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const [isLessor, setIsLessor] = useState(false)
  const [verifyingCreditCard, setVerifyingCreditCard] = useState(false)
  const [redirectInfo, setRedirectInfo] = useState<any>({})
  const [form, setForm] = useState<any>({
    cvc: '',
    expiry: '',
    focus: undefined,
    name: '',
    number: '',
  })
  const {
    cvc,
    expiry,
    focus,
    name,
    number,
  } = form
  const [qrCode, setQrCode] = useState(null)

  useEffect(()=>{
    if (props.show) return

    setTimeout(()=>setActiveIndex(0), 300)
  }, [props.show])

  const handleInputFocus = ({ target }: any) => {
    setForm({...form, focus: target.name})

  };

const handleInputChange = ({ target }: any) => {
  if (target.name === "number") {
    target.value = formatCreditCardNumber(target.value);
  } else if (target.name === "expiry") {
    target.value = formatExpirationDate(target.value);
  } else if (target.name === "cvc") {
    target.value = formatCVC(target.value);
  }

  setForm({ ...form, [target.name]: target.value });
};

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={props.show}
      onHide={props.onHide}
    >
      <Modal.Header closeButton />
      <Modal.Body className="credit-purchase__payment-modal">
        <Carousel
        slide={false}
        interval={null}
        keyboard={false}
        controls={false}
        touch={false}
        indicators={false}
        activeIndex={activeIndex}
        >
          <Carousel.Item>
            <div className="column-center mb-3">
              <img src={logo} className="login__logo" />
              <div className="mt-2">โปรดเลือกวิธีชำระเงิน</div>
            </div>
            <div onClick={()=>{
              setActiveIndex(1)
              api.request('payment', (r, s)=>{
                setQrCode(r.image.uri)
              }, api.form({
                method: 'qr_code',
                type: 'credit_purchase',
                netPrice: item.value
              }), 'POST')
            }} className="login__selector shadow">
              <div>
                <img src={thaiQrIcon} />
                <strong>Thai QR Payment</strong>
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
            <div onClick={()=>{
              setActiveIndex(2)
            }} className="login__selector shadow">
              <div>
                <img src={creditCardIcon} />
                <strong>Credit Card</strong>
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </Carousel.Item>
          <Carousel.Item className="px-3">
            <div className="column-center mb-3">
              <img src={logo} className="login__logo" />
              <div className="mt-2">Thai QR Payment</div>
              <small>หลังชำระเงินเสร็จแล้ว ให้กดปุ่มชำระเงินสำเร็จแล้วด้านล่าง</small>
            </div>
            { qrCode ?
            <div className="row-center">
              <img src={qrCode} style={{width: '70%', minWidth: 300}} />
            </div>:
            <div className="row-center w-100" style={{height: 200}}>
            <Spinner size="sm" className="me-2" />
            ระบบกำลังสร้าง QR Code..
            </div>
            }
            <Button onClick={()=>{
              onFinish()
            }} className="w-100 mt-3">ชำระเงินสำเร็จแล้ว</Button>
            <div className="row-center mt-1 cursor-pointer" onClick={()=>setActiveIndex(0)}>
              <FontAwesomeIcon icon={faArrowLeft} /><span className="ms-2">ย้อนกลับ</span>
            </div>
          </Carousel.Item>
          <Carousel.Item className="px-3">
            <div className="column-center mb-3">
              <img src={logo} className="login__logo" />
              <div className="mt-2">Credit Card</div>
              <small>by GB Prime Pay</small>
            </div>
            <div>
              <Cards
                cvc={cvc}
                expiry={expiry}
                focused={focus}
                name={name}
                number={number}
              />
              <Form.Control
                type="tel"
                placeholder="Card Number"
                name="number"
                pattern="[0-9\s]{13,19}"
                autoComplete="cc-number"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                className="mt-3 mb-2"
               />
               <Form.Control
                 type="text"
                 placeholder="Name"
                 name="name"
                 autoComplete="cc-name"
                 onChange={handleInputChange}
                 onFocus={handleInputFocus}
                 className="mb-2"
                />
              <Row>
                <Col xs={12} md={6}>
                  <Form.Control
                    type="tel"
                    placeholder="Expiry"
                    name="expiry"
                    autoComplete="cc-exp"
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    className="mb-2"
                   />
                </Col>
                <Col xs={12} md={6}>
                  <Form.Control
                    type="tel"
                    placeholder="cvc"
                    name="cvc"
                    autoComplete="cc-csc"
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    className="mb-2"
                   />
                </Col>
              </Row>
            </div>
            <Form ref={formRef} name="gb_prime"
             action="https://api.globalprimepay.com/v2/tokens/3d_secured"
             method="POST">
             <input type="hidden" name="publicKey" value={redirectInfo.publicKey} />
             <input type="hidden" name="gbpReferenceNo" value={redirectInfo.gbpReferenceNo} />
            <Button disabled={verifyingCreditCard} onClick={()=>{
              setVerifyingCreditCard(true)
              api.request('payment', (r, s)=>{
                setVerifyingCreditCard(false)
                if (!s) return

                setRedirectInfo({publicKey: r.publicKey, gbpReferenceNo: r.metadata.gbpReferenceNo})
                setTimeout(()=>{
                  formRef.current?.submit();
                }, 300)
              }, api.form({
                method: 'credit_card',
                type: 'credit_purchase',
                netPrice: item.value,
                card: {
                  number: number.replace(/\s/g, ''),
                  name,
                  expirationMonth: expiry.slice(0, 2),
                  expirationYear: expiry.slice(-2),
                  securityCode: cvc
                }
              }), 'POST')
            }} className="w-100 mt-3">{verifyingCreditCard?'Verifying Card...':'PAY NOW'}</Button>
            </Form>
            <div className="row-center mt-1 cursor-pointer" onClick={()=>setActiveIndex(0)}>
              <FontAwesomeIcon icon={faArrowLeft} /><span className="ms-2">ย้อนกลับ</span>
            </div>
          </Carousel.Item>
        </Carousel>
      </Modal.Body>
    </Modal>
  )
}
