import {useState, useEffect} from 'react'
import { Container, Row, Col, Button, Modal, Carousel, Form, Badge } from 'react-bootstrap';
import renter from '../../assets/images/renter.png'
import lessor from '../../assets/images/lessor.png'
import logo from '../../assets/images/logo.png'
import logoWhite from '../../assets/images/logo-white.png'
import cover from '../../assets/images/mock/accommodation-cover.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faArrowLeft, faArrowRight, faCopy, faCheck } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import api from '../../utils/Api'
import { dateRange } from '../../utils/Functions'
import moment from 'moment'
import _ from 'lodash'
import {numberFormat} from '../../utils/Functions'
import Select from 'react-select';

const reasons = [
  'ขออภัย เพิ่งมีผู้เช่า walk in เข้ามา',
  'ขออภัย มีอุปกรณ์สำคัญของที่พักชำรุด',
  'ขออภัย ระบบไฟฟ้า/ประปา ขัดข้อง',
  'เหตุผล อื่นๆ'
]

export const bookingStates = [{
  value: 'requested',
  label: 'รอเจ้าของที่พักยืนยันการจอง',
  color: 'warning'
},{
  value: 'confirmed',
  label: 'เจ้าของที่พักยืนยันการจองแล้ว',
  color: 'success'
},{
  value: 'paid',
  label: 'ชำระเงินแล้ว',
  color: 'success'
},{
  value: 'checked_in',
  label: 'เช็คอินแล้ว',
  color: 'primary'
},{
  value: 'checked_out',
  label: 'เช็คเอาท์แล้ว',
  color: 'primary'
},{
  value: 'renter_cancelled',
  label: 'ผู้เช่ายกเลิก',
  color: 'danger'
},{
  value: 'lessor_cancelled',
  label: 'เจ้าของที่พักยกเลิก',
  color: 'danger'
}]

export function CouponItem(props: any) {
  const {item, onUpdate} = props
  const u = localStorage.getItem('userKey')
  const [isCopied, setIsCopied] = useState(false)

  return (
    <div className="card-item coupon__card position-relative">
      <div className="coupon__logo-container">
        <div className="bg-primary" style={{
              width: 170,
              height: 170,
              borderRadius: 85,
              position: 'absolute',
              top: -85,
              right: -85,
        }} />
        <img src={logoWhite} className="position-relative"
         style={{width: 50, height: 50*176/240}} />
      </div>
      <div className="position-relative overflow-hidden p-3">
        <h4 className="mb-0">
          <strong>
            ส่วนลด <span className="text-blue">{item.discountPercent}%</span>
          </strong>
        </h4>
        <small className="text-primary">กรอกโค้ด</small>
        <h3 className="text-primary">
          <strong>
            {item.code}
          </strong>
        </h3>
        <div className="coupon__shape bg-primary" />
      </div>
      <div style={{ height: 1, overflow: 'hidden' }}>
        <div style={{ height: 2, borderWidth: 3, borderColor: 'black', borderStyle: 'dashed' }} />
      </div>
      <div className="py-2 px-3 mt-2">
        <h6 className="text-secondary">รายละเอียด</h6>
        <ul>
        <li>ไม่กำหนดราคาขั้นต่ำในการจอง</li>
        <li>ใช้ได้ถึง {moment(item.endAt).format('ll')}</li>
        <li>เฉพาะบ้านพักที่เข้าร่วมรายการเท่านั้น</li>
        <li className="text-danger">จำกัดเพียง {item.quantity} สิทธิ์เท่านั้น !!</li>
        </ul>
        <Button variant={isCopied?'success':'primary'} className="w-100" onClick={()=>{
          navigator.clipboard.writeText(item.code)
          toast.success('คัดลอกโค้ดสำเร็จ')
          setIsCopied(true)
        }}>
          คัดลอก{isCopied?'แล้ว':'โค้ด'} <FontAwesomeIcon icon={isCopied?faCheck:faCopy} />
        </Button>
      </div>
    </div>
  )
}
