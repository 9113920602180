import React from "react";
import {Map, Marker, GoogleApiWrapper, InfoWindow, Polyline, Polygon}  from 'google-maps-react';
import {Row, Col, Card, Table, Button, Tab, Nav, Modal, Form, InputGroup} from 'react-bootstrap';

class GoogleMap extends React.Component {
    static defaultProps = {
      position: null
    }

    state = {
        activeMarker: {},
        selectedPlace: {},
        showingInfoWindow: false,
        position: this.props.position,
        markerPosition: this.props.position
    };

    onMarkerClick = (props: any, marker: any) =>
        this.setState({
            activeMarker: marker,
            selectedPlace: props,
            showingInfoWindow: true
        });

    onInfoWindowClose = () =>
        this.setState({
            activeMarker: null,
            showingInfoWindow: false
        });

    onMapClicked = (t, map, c) => {
        const pos = {
          lat: c.latLng.lat(),
          lng: c.latLng.lng()
        }

        this.setState({
            markerPosition: pos
        });

        this.props.onChangeLocation(pos)
    };

    componentDidMount() {
        setTimeout(()=>this.renderAutoComplete(), 300)
    }

    componentDidUpdate(prevProps) {
        if (this.props.position != prevProps.position) {
                  console.log(this.props.position)
          this.setState({position: this.props.position, markerPosition: this.props.position})
        }
    }

    onSubmit(e) {
        e.preventDefault();
    }

    renderAutoComplete() {
        const { google, map } = this.props;

        if (!google || !map) return;

        const autocomplete = new google.maps.places.Autocomplete(this.autocomplete);
        autocomplete.bindTo('bounds', map);


        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();

            if (!place.geometry) return;

            if (place.geometry.viewport) map.fitBounds(place.geometry.viewport);
            else {
                map.setCenter(place.geometry.location);
                map.setZoom(17);
            }

            this.setState({ position: place.geometry.location, markerPosition: place.geometry.location });
            this.props.onChangeLocation({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()})
        });
    }

    render() {
        const { position, markerPosition } = this.state;

        return (
          <div className="d-flex flex-column" style={{height: '100%'}}>
            <Form onSubmit={this.onSubmit}>
                <InputGroup className="mb-3">
                    <Form.Control ref={ref => (this.autocomplete = ref)} type='text' placeholder='Search your favorite place' />
                </InputGroup>
            </Form>
              <div className="position-relative" style={{flex: 1}}>
                  <Map
                      className='map'
                      {...this.props}
                      initialCenter={position||undefined}
                      center={position||undefined}
                      centerAroundCurrentLocation={!position}
                      onClick={this.onMapClicked}
                       >
                      <Marker position={markerPosition} />
                  </Map>
                </div>
            </div>

        );
    }
}

const MapWrapper = props => (
  <div className="position-relative" style={{width: '100%', height: '70vh'}}>
    <Map className="map" google={props.google}  visible={false}>
      <GoogleMap {...props} />
    </Map>
  </div>
);

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDtUn1hJE8LHIyoCHUP-EcfP9POdqvYATA',
    libraries: ['places'],
})(MapWrapper);
