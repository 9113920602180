import {useState, useEffect, useMemo} from 'react'
import { Button, Row, Col, Offcanvas, Accordion, Form, Badge } from 'react-bootstrap'
import api from '../../utils/Api'
import Counter from './Counter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faRedo } from '@fortawesome/free-solid-svg-icons'

const priceOptions = [{
  label: 'น้อยกว่า ฿1,000',
  value: [0, 999]
},{
  label: '฿1,000-฿3,000',
  value: [1000, 3000]
},{
  label: '฿3,000-฿5,000',
  value: [3000, 5000]
},{
  label: '฿5,000-฿7,000',
  value: [5000, 7000]
},{
  label: '฿7,000-฿9,000',
  value: [7000, 9000]
},{
  label: '฿9,000-฿10,000',
  value: [9000, 10000]
},{
  label: '฿10,000-฿20,000',
  value: [10000, 20000]
},{
  label: 'มากกว่า ฿20,000',
  value: [20001, '']
},
]

const pricePerPersonOptions = [{
  label: 'น้อยกว่า ฿300',
  value: [0, 299]
},{
  label: '฿300-฿500',
  value: [300, 500]
},{
  label: '฿500-฿700',
  value: [500, 700]
},{
  label: '฿700-฿1,000',
  value: [700, 1000]
},{
  label: '฿1,000-฿3,000',
  value: [1000, 3000]
},{
  label: '฿3,000-฿5,000',
  value: [3000, 5000]
},{
  label: '฿5,000-฿10,000',
  value: [5000, 10000]
},{
  label: 'มากกว่า ฿10,000',
  value: [10001, '']
},
]

const defaultFilter = {
  'tagIds': [],
  'locationId': '',
  'normalDayPrice': ['', ''],
  'normalDayPricePerPerson': ['', ''],
  'normalDayMaxPeople': '',
  'maxRooms': '',
  'maxBeds': '',
  'maxBathrooms': ''
}

export function SearchFilter(props: any){
  const [show, setShow] = useState(false)
  const [tags, setTags] = useState<{[key: string]: []}>({
    'popular': [],
    'featured': [],
    'facility': [],
    'electric': [],
    'activity': [],
    'style': []
  })
  const [filter, setFilter] = useState<{[key: string]: any}>({...defaultFilter})
  const [locations, setLocations] = useState([])
  const [activeKey, setActiveKey] = useState<any>("0")

  const fetchTags = () => {
    api.request('tag', (data)=>{
      const _tags = {...tags}

      for (let k in tags) {
        const key:string = k
        _tags[key] = data.filter((props:{type: string})=>props.type === k).map((props: {id: number, name: string})=>
        ({name: props.name, id: props.id}))
      }

      setTags(_tags)
    })
  }

  const fetchLocations = () => {
    api.request('location', (data)=>{
      setLocations(data)
    })
  }

  const setTagIds = (checked: boolean, id: number) => {
      let ids = [...filter.tagIds]
      const index = ids.indexOf(id)
      if (index > -1 && !checked) {
        ids.splice(index, 1)
      } else if (checked) {
        ids.push(id)
      }

      setFilter({...filter, tagIds: ids})
  }

  const numberCount = useMemo(()=>{
    const members = ['normalDayMaxPeople','maxRooms','maxBeds','maxBathrooms']
    let c = 0
    for (let m of members) {
      if (filter[m]) {
        c++
      }
    }
    return c
  }, [filter])

  const {featuredCount, popularCount, facilityCount, electricCount,
    activityCount,styleCount} = useMemo(()=>{
    const c:any = {
      'popularCount': 0,
      'featuredCount': 0,
      'facilityCount': 0,
      'electricCount': 0,
      'activityCount': 0,
      'styleCount': 0
    }
    for (let tagType in tags) {
      c[tagType+'Count'] += tags[tagType].filter(({id})=>filter.tagIds.indexOf(id)>-1).length
    }
    return c
  }, [filter, tags])

  useEffect(()=>{
    if (props.show === show) return

    if (props.show) {
      fetchLocations()
      fetchTags()
    }

    setShow(props.show)
  }, [props.show])

  return (
    <Offcanvas className="offcanvas__filter" show={show} onHide={()=>props.onHide()} placement={props.placement}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="text-primary">ตัวกรอง</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Accordion onSelect={(k)=>setActiveKey(k)} defaultActiveKey={activeKey}>
          <Accordion.Item eventKey="0">
            <Accordion.Header><div className="w-100 row-edge-center me-3">สถานที่ยอดนิยม{!!filter.locationId &&
              <Badge className="ms-2" pill bg="primary" text="light">1</Badge>}</div></Accordion.Header>
            <Accordion.Body>
              <Row>
                { locations.map(({id, name})=>(
                  <Col md={4} xs={6} key={id} className="mb-2">
                  <Form.Check
                      type={'radio'}
                      id={`location-${id}`}
                      label={name}
                      checked={filter.locationId === id}
                      onChange={(e)=>{
                        // const checked = e.target.checked
                        // let ids = [...filter.locationIds]
                        // const index = ids.indexOf(id)
                        // if (index > -1 && !checked) {
                        //   ids.splice(index, 1)
                        // } else if (checked) {
                        //   ids.push(id)
                        // }

                        setFilter({...filter, locationId: id})
                      }}
                    />
                  </Col>
                ))
               }
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header><div className="w-100 row-edge-center me-3">ราคาเริ่มต้น(ต่อคืน){!!(filter.normalDayPrice[0]||filter.normalDayPrice[1]) &&
              <Badge className="ms-2" pill bg="primary" text="light">1</Badge>}</div></Accordion.Header>
            <Accordion.Body>
              <div className="row-vcenter">
                <Form.Control type="number" value={filter.normalDayPrice[0]}
                onChange={(e)=>setFilter({...filter,
                   normalDayPrice: [e.target.value, filter.normalDayPrice[1]]
                 })}
                min={0} step={500} placeholder="ราคาต่ำสุด" />
                <strong className="mx-2">-</strong>
                <Form.Control type="number"
                onChange={(e)=>setFilter({...filter,
                   normalDayPrice: [filter.normalDayPrice[0], e.target.value]
                 })}
                value={filter.normalDayPrice[1]} min={0} step={500} placeholder="ราคาสูงสุด" />
              </div>
              <Row>
                { priceOptions.map(({label, value}, index)=>(
                  <Col xs={6} key={index} className="mt-2">
                  <Form.Check
                      type={'radio'}
                      id={`price-${index}`}
                      onChange={(e)=>{
                        const checked = e.target.checked
                        setFilter({...filter,
                           normalDayPrice: [checked?value[0]:'', checked?value[1]:'']
                         })
                      }}
                      label={label}
                      checked={filter.normalDayPrice[0]===value[0] && filter.normalDayPrice[1]===value[1]}
                    />
                  </Col>
                ))
               }
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header><div className="w-100 row-edge-center me-3">ราคาเฉลี่ยต่อคน(ต่อคืน){!!(filter.normalDayPricePerPerson[0]||filter.normalDayPricePerPerson[1]) &&
              <Badge className="ms-2" pill bg="primary" text="light">1</Badge>}</div></Accordion.Header>
            <Accordion.Body>
              <div className="row-vcenter">
                <Form.Control type="number" value={filter.normalDayPricePerPerson[0]}
                onChange={(e)=>setFilter({...filter,
                   normalDayPricePerPerson: [e.target.value, filter.normalDayPricePerPerson[1]]
                 })}
                min={0} step={500} placeholder="ราคาต่ำสุด" />
                <strong className="mx-2">-</strong>
                <Form.Control type="number"
                onChange={(e)=>setFilter({...filter,
                   normalDayPricePerPerson: [filter.normalDayPricePerPerson[0], e.target.value]
                 })}
                value={filter.normalDayPricePerPerson[1]} min={0} step={500} placeholder="ราคาสูงสุด" />
              </div>
              <Row>
                { pricePerPersonOptions.map(({label, value}, index)=>(
                  <Col xs={6} key={index} className="mt-2">
                  <Form.Check
                      type={'radio'}
                      id={`price_person-${index}`}
                      onChange={(e)=>{
                        const checked = e.target.checked
                        setFilter({...filter,
                           normalDayPricePerPerson: [checked?value[0]:'', checked?value[1]:'']
                         })
                      }}
                      label={label}
                      checked={filter.normalDayPricePerPerson[0]===value[0] && filter.normalDayPricePerPerson[1]===value[1]}
                    />
                  </Col>
                ))
               }
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header><div className="w-100 row-edge-center me-3">เลือกจำนวน{!!numberCount &&
              <Badge className="ms-2" pill bg="primary" text="light">{numberCount}</Badge>}</div></Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col xs={6} className="mb-2">
                  <span className="unselectable">จำนวนคน</span>
                  <Counter defaultCount={filter.normalDayMaxPeople} onChange={(normalDayMaxPeople)=>setFilter({...filter, normalDayMaxPeople})} />
                </Col>
                <Col xs={6} className="mb-2">
                  <span className="unselectable">ห้องนอน</span>
                  <Counter defaultCount={filter.maxRooms} onChange={(maxRooms)=>setFilter({...filter, maxRooms})} />
                </Col>
                <Col xs={6} className="mb-2">
                  <span className="unselectable">เตียง</span>
                  <Counter defaultCount={filter.maxBeds} onChange={(maxBeds)=>setFilter({...filter, maxBeds})} />
                </Col>
                <Col xs={6} className="mb-2">
                  <span className="unselectable">ห้องน้ำ</span>
                  <Counter defaultCount={filter.maxBathrooms} onChange={(maxBathrooms)=>setFilter({...filter, maxBathrooms})} />
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header><div className="w-100 row-edge-center me-3">จุดเด่น{!!featuredCount &&
              <Badge className="ms-2" pill bg="primary" text="light">{featuredCount}</Badge>}</div></Accordion.Header>
            <Accordion.Body>
              <Row>
                { tags.featured.map(({id, name})=>(
                  <Col md={4} xs={6} key={id} className="mb-2">
                  <Form.Check
                      type={'checkbox'}
                      id={`tag-${id}`}
                      label={name}
                      onChange={(e)=>setTagIds(e.target.checked, id)}
                      checked={filter.tagIds.indexOf(id) > -1}
                    />
                  </Col>
                ))
               }
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header><div className="w-100 row-edge-center me-3">สไตล์บ้านพัก{!!styleCount &&
              <Badge className="ms-2" pill bg="primary" text="light">{styleCount}</Badge>}</div></Accordion.Header>
            <Accordion.Body>
              <Row>
                { tags.style.map(({id, name})=>(
                  <Col md={4} xs={6} key={id} className="mb-2">
                  <Form.Check
                      type={'checkbox'}
                      id={`tag-${id}`}
                      label={name}
                      onChange={(e)=>setTagIds(e.target.checked, id)}
                      checked={filter.tagIds.indexOf(id) > -1}
                    />
                  </Col>
                ))
               }
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header><div className="w-100 row-edge-center me-3">กิจกรรม{!!activityCount &&
              <Badge className="ms-2" pill bg="primary" text="light">{activityCount}</Badge>}</div></Accordion.Header>
            <Accordion.Body>
              <Row>
                { tags.activity.map(({id, name})=>(
                  <Col md={4} xs={6} key={id} className="mb-2">
                  <Form.Check
                      type={'checkbox'}
                      id={`tag-${id}`}
                      label={name}
                      onChange={(e)=>setTagIds(e.target.checked, id)}
                      checked={filter.tagIds.indexOf(id) > -1}
                    />
                  </Col>
                ))
               }
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header><div className="w-100 row-edge-center me-3">เครื่องใช้ไฟฟ้า{!!electricCount &&
              <Badge className="ms-2" pill bg="primary" text="light">{electricCount}</Badge>}</div></Accordion.Header>
            <Accordion.Body>
              <Row>
                { tags.electric.map(({id, name})=>(
                  <Col md={4} xs={6} key={id} className="mb-2">
                  <Form.Check
                      type={'checkbox'}
                      id={`tag-${id}`}
                      label={name}
                      onChange={(e)=>setTagIds(e.target.checked, id)}
                      checked={filter.tagIds.indexOf(id) > -1}
                    />
                  </Col>
                ))
               }
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header><div className="w-100 row-edge-center me-3">สิ่งอำนวยความสะดวก อื่น ๆ{!!facilityCount &&
              <Badge className="ms-2" pill bg="primary" text="light">{facilityCount}</Badge>}</div></Accordion.Header>
            <Accordion.Body>
              <Row>
                { tags.facility.map(({id, name})=>(
                  <Col md={4} xs={6} key={id} className="mb-2">
                  <Form.Check
                      type={'checkbox'}
                      id={`location-${id}`}
                      label={name}
                      onChange={(e)=>setTagIds(e.target.checked, id)}
                      checked={filter.tagIds.indexOf(id) > -1}
                    />
                  </Col>
                ))
               }
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="my-3 row-vcenter">
          <Button onClick={()=>{
            setFilter({...defaultFilter})
            if (props.onSubmit) {
              props.onSubmit({})
            }
            props.onHide()
          }} variant="secondary"><FontAwesomeIcon icon={faRedo} /> ล้างค่า</Button>
          <Button onClick={()=>{
            if (props.onSubmit) {
              props.onSubmit(filter)
            }
            props.onHide()
          }} className="mx-3" variant="primary"><FontAwesomeIcon icon={faSearch} /> ค้นหา</Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}
