import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Badge, Modal } from 'react-bootstrap';
import {Layout} from '../components'
import {AccommodationItem} from '../components/app/Accommodation'
import {SearchFilter} from '../components/app/Home'
import api from '../utils/Api'
import _ from 'lodash'
import { Swiper, SwiperSlide } from 'swiper/react';
import {Pagination, Navigation} from 'swiper'
import iconSea from '../assets/images/icon-sea.png'
import iconHomeTree from '../assets/images/home-tree.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faPlus, faArrowRight, faCalendarAlt, faMap } from '@fortawesome/free-solid-svg-icons'
import {RenterFilterNumberModal} from '../components/app/RenterNumber'
import Calendar from '../components/app/Calendar'
import moment from 'moment'
import {HEX2RGBA} from '../utils/Functions'
import accommodationCover from '../assets/images/mock/accommodation-cover.png'

const mockRegions = [
  {
    value: 'c',
    label: 'ภาคกลาง',
    lat: 13.724484,
    lon: 100.009188,
  },
  {
    value: 'e',
    label: 'ภาคตะวันออก',
    lat: 13.088112297864585,
    lon: 101.32418568016946,
  },
  {
    value: 'n',
    label: 'ภาคเหนือ',
    lat: 18.63582174856306,
    lon: 99.40686235066482,
  },
  {
    value: 's',
    label: 'ภาคใต้',
    lat: 8.541675983487961,
    lon: 98.738832787435,
  },
  {
    value: 'w',
    label: 'ภาคตะวันตก',
    lat: 13.732228,
    lon: 99.675798,
  },
  {
    value: 'ne',
    label: 'ภาคอีสาน',
    lat: 16.174984224306506,
    lon: 103.25753354042922,
  },
]

export default function Home() {
  const [paginate, setPaginate] = useState({data: [], page: 1, meta: {total: null}})
  const [show, setShow] = useState(false)
  const [modal, setModal] = useState('')
  const [filterCount, setFilterCount] = useState<number>(0)
  const [tags, setTags] = useState<object[]>([])
  const [locations, setLocations] = useState<any>([])
  const [filter, setFilter] = useState<any>({})
  const [accommodationFilter, setAccommodationFilter] = useState<any>({tagIds: []})
  const fetch = (params = {}) => {
    api.request('accommodation', (r: any)=>{
      setPaginate(r)
    }, params)
  }
  useEffect(()=>{
    fetch()
  }, [])

  useEffect(()=>{
    api.request('tag', (res)=>{
      setTags(res)
    }, {type: 'featured'})
  }, [])

  useEffect(()=>{
    if (modal !== 'location') return

    api.request('location', (res)=>{
      setLocations(res)
    })
  }, [modal])

  const { data, meta } = paginate

  return (
    <Layout headerStyle={{paddingBottom: 90}}>
      <div className="search-filter py-3">
        <Container style={{marginTop: -100}}>
          <Row>
            <Col xs={12} className="mb-4 position-relative">
              <img src={iconHomeTree} style={{width:80, position: 'absolute', left: -10, top: 10}} />
              <img src={iconHomeTree} style={{width:120, position: 'absolute', right: -45, top: 10}} />
              <Row className="less-shadow rounded mx-3 p-3 position-relative">
                <Col xs={12}>
                  <div onClick={()=>setModal('location')} className="blur-less-shadow w-100 rounded row-vcenter px-2 py-3">
                    <FontAwesomeIcon className="text-primary" icon={faSearch} />
                    <span className="mx-2">{filter.locationId?_.find(locations, ['id', filter.locationId])?.name:'เลือกสถานที่'}</span>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4} className="mt-3">
                  <div onClick={()=>setModal('renter_number')}
                  className="blur-less-shadow w-100 cursor-pointer rounded row-edge-center px-2 py-2">
                    <span className="mx-2">ผู้เข้าพัก</span>
                    <div className={"counter__button"}
                      >
                        <FontAwesomeIcon className={'text-primary'} icon={faPlus} />
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4} className="mt-3">
                  <div className="blur-less-shadow w-100 rounded row-edge-center px-2 py-2">
                    <div style={{width: 130}} onClick={()=>setModal('calendar')} className="row-edge-center border rounded py-2 px-2 cursor-pointer">
                      <span className="me-2">{filter.startDate?moment(filter.startDate).format('DD/MM/YYYY'):'เช็คอิน'}</span>
                      <FontAwesomeIcon className="text-primary" icon={faCalendarAlt} />
                    </div>
                    <FontAwesomeIcon icon={faArrowRight} />
                    <div style={{width: 130}}  onClick={()=>setModal('calendar')} className="row-edge-center border rounded py-2 px-2 cursor-pointer">
                      <span className="me-2">{filter.endDate?moment(filter.endDate).format('DD/MM/YYYY'):'เช็คเอาท์'}</span>
                      <FontAwesomeIcon className="text-primary" icon={faCalendarAlt} />
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4} className="mt-3">
                  <Button variant="success" className="me-3" size="lg"><FontAwesomeIcon className="text-light" icon={faMap} />{" "}แสดงบนแผนที่</Button>
                  <Button onClick={()=>{
                    fetch({accommodationFilter, ...filter})
                  }} size="lg"><FontAwesomeIcon className="text-light" icon={faSearch} />{" "}ค้นหา</Button>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="mb-3">
              <Swiper
                modules={[Navigation]}
                navigation
              //  slidesPerView={8}
                spaceBetween={20}
                breakpoints={{
                  380: {
                    slidesPerView: 2
                  },
                  576: {
                    slidesPerView: 3
                  },
                  768: {
                    slidesPerView: 5
                  },
                  1200: {
                    slidesPerView: 6
                  },
                  1400: {
                    slidesPerView: 8
                  }
                }}
              >
                { tags.map(({id, name, icon}: any)=>(
                  <SwiperSlide key={id}>
                    <div onClick={()=>{
                      let ids = [...accommodationFilter.tagIds]
                      const index = ids.indexOf(id)
                      if (index > -1) {
                        ids.splice(index, 1)
                      } else {
                        ids.push(id)
                      }

                        setAccommodationFilter({...accommodationFilter, tagIds: ids})

                       fetch({accommodationFilter: {...accommodationFilter, tagIds: ids}})
                    }} className={"column-center tag-item" + (accommodationFilter.tagIds.indexOf(id)>-1?' tag-item--active':'')}>
                      <img src={icon||iconSea} style={{width: 30, height: 30}} />
                      <span>{name}</span>
                    </div>
                  </SwiperSlide>
                ))
                }
              </Swiper>
            </Col>
            <Col className="row-edge-center" xs={12}>
            <strong>{filterCount?'พบที่พัก':'ที่พักทั้งหมด'} {paginate.meta.total} แห่ง</strong>
            <Button onClick={()=>setShow(true)} variant="primary">ตัวกรอง {!!filterCount &&
              <Badge bg="light" text="primary">{filterCount}</Badge>}</Button>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="pb-4">
           { data.map((item, index)=>(
             <Col key={index} xs={12} sm={6} lg={4} xxl={3}>
               <AccommodationItem item={item} />
             </Col>
           ))
           }
        </Row>
      </Container>
      <SearchFilter placement="end" show={show}
       onSubmit={(_filter: any)=>{
         let c = 0
         for (let f in _filter) {
           if (Array.isArray(_filter[f])) {
             const length = _.compact(_filter[f]).length
             if (length === 0) continue;
             if (f === 'tagIds') {
               c += length
               continue;
             }
           } else if (!_filter[f]) continue;

           c++
         }
         setAccommodationFilter({...accommodationFilter, ..._filter})
         setFilterCount(c)
         fetch({accommodationFilter: _filter, ...filter})
       }}
       onHide={()=>setShow(false)} />
       <Modal
         aria-labelledby="contained-modal-title-vcenter"
         show={modal=='calendar'}
         onHide={()=>{
           setFilter({...filter, startDate: null, endDate: null})
           setModal('')
         }}
       >
         <Modal.Header closeButton>
           <Modal.Title>
             เช็คอิน-เอาท์
           </Modal.Title>
         </Modal.Header>
         <Modal.Body className="">
         <Calendar
           onSubmit={(f: any)=>{
             setFilter({...filter, ...f})
             setModal('')
           }}
           onCancel={()=>{
             setModal('')
           }}
         />
         </Modal.Body>
       </Modal>
       <Modal
         aria-labelledby="contained-modal-title-vcenter"
         show={modal=='location'}
         onHide={()=>{
           setModal('')
         }}
         size="lg"
       >
         <Modal.Header closeButton>
           <Modal.Title>
             เลือกสถานที่
           </Modal.Title>
         </Modal.Header>
         <Modal.Body className="">
            <div>
              <div><strong className="text-primary">เลือกจากภาค</strong></div>
              <div className="d-flex flex-wrap py-2">
                {
                  mockRegions.map(({label, value})=>(
                    <div onClick={()=>setFilter({...filter, region: value})}
                    style={{backgroundColor: filter.region === value?HEX2RGBA('#00C5F0', 0.2):undefined}}
                      className={"me-3 border rounded cursor-pointer py-1 px-2" + (filter.region === value?' border-primary text-primary':'')}>
                    {label}
                    </div>
                  ))
                }
              </div>
              <div className="mt-3"><strong className="text-primary">สถานที่ยอดนิยม</strong></div>
              <div className="d-flex flex-wrap py-2">
                {
                  locations.map(({id, name}: any)=>(
                    <div onClick={()=>setFilter({...filter, locationId: id})}
                    style={{background: 'url('+accommodationCover+') no-repeat center center / cover', width: 100, height: 80, overflow: 'hidden'}}
                      className={"position-relative d-flex mb-3 align-items-end justify-content-end me-3 border rounded cursor-pointer py-1 px-2" + (filter.locationId === id?' border-primary text-primary':' text-light')}>
                      <div style={{position: 'relative', zIndex: 1}}>{name}</div>
                      <div style={{position: 'absolute', width: '100%', height: '100%', background: 'rgba(0,0,0,0.4)', left: 0, top: 0, zIndex: 0}} />
                    </div>
                  ))
                }
              </div>
            </div>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>{
                setFilter({...filter, locationId: '', region: ''})
                setModal('')
              }}>Clear</Button>
              <Button onClick={()=>setModal('')}>Submit</Button>
            </Modal.Footer>
         </Modal.Body>
       </Modal>
       <RenterFilterNumberModal
        form={filter}
        data={null}
        show={modal=='renter_number'}
        onHide={()=>setModal('')}
        onSubmit={(f: any, p: any)=>{
          setFilter({...filter, ...f})
          setModal('')
        }}
       />
    </Layout>
  )
}
