import {useState, useEffect} from 'react'
import { Container, Row, Col, Offcanvas, Accordion, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'

const Counter = (props: {style?: object, onChange?: (count: number|null)=>void, defaultCount?: number, enableInput?: boolean,
  isPrice?: boolean, suffix?: any, min?: number, max?: number, step?: number}) => {
  const [count, setCount] = useState<number>(0)
  const min = props.min || 0
  const max = props.max !== undefined ? props.max : 99999999
  const step = props.step || 1

  const _setCount = (c: number) => {
    if (!props.onChange) return

    setCount(c)
    props.onChange(c<0?null:c)
  }

  useEffect(()=>{
    if (props.defaultCount !== undefined && !_.isEqual(props.defaultCount, count)) {
      setCount(props.defaultCount)
    }
  }, [props.defaultCount])

  const preventDecreasing = min >= count
  const preventIncreasing = max <= count

  return <div className="row-vcenter counter">
    <div aria-disabled={preventDecreasing} className={"counter__button"+(preventDecreasing?' counter__button--disabled':'')}
    onClick={()=>!preventDecreasing && _setCount(Math.max(min, count-step))}>
      <FontAwesomeIcon className={preventDecreasing?'text-muted':'text-primary'} icon={faMinus} />
    </div>
    <Form.Control step={step} min={min} max={max}
     className={"counter__input mx-2 " + (step >= 100?'counter__input--lg':'counter__input--sm')} value={count === -1?'':count} type="number" onChange={(e)=>{
      const c = e.target.value
      _setCount(c?parseInt(c):c===''?-1:0)
    }} />
    <div aria-disabled={preventIncreasing} className={"counter__button"+(preventIncreasing?' counter__button--disabled':'')}
      onClick={()=>!preventIncreasing && _setCount(Math.min(count+step, max))}>
        <FontAwesomeIcon className={preventIncreasing?'text-muted':'text-primary'} icon={faPlus} />
    </div>
  </div>
}

export default Counter
