import {useState, useEffect} from 'react'
import { Container, Row, Col, Button, Modal, Carousel, Form } from 'react-bootstrap';
import renter from '../../assets/images/renter.png'
import lessor from '../../assets/images/lessor.png'
import logo from '../../assets/images/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import api from '../../utils/Api'
import { dateRange } from '../../utils/Functions'
import Counter from '../../components/app/Counter'
import moment from 'moment'

const getPrice = (price: any, baseKey: string, suffix: string) => {
  return price[baseKey+suffix] || price['normalDay'+suffix] || 0
}

const getPriceFromDate = ({startDate, endDate, people, children, pets}: any, price: any, calendars = []) => {
    const dates = dateRange(startDate, moment(endDate).subtract(1, 'day'))
    let basePrice = 0,
      extraPeoplePrice = 0,
      extraPetPrice = 0,
      maxPeople = 0

    for (let date of dates) {
      const numberInWeek = parseInt(date.format('d'))
      const dayInWeek = date.format('dddd').toLowerCase()

      const priceBaseKey =  numberInWeek >= 1 && numberInWeek <= 4 ? 'normalDay':dayInWeek
      const netPeople = (people||0) + (children||0)
      maxPeople = getPrice(price, priceBaseKey,'MaxPeople') > maxPeople ? getPrice(price, priceBaseKey,'MaxPeople') : maxPeople
      basePrice += getPrice(price, priceBaseKey,'Price')
      extraPeoplePrice += getPrice(price, priceBaseKey,'PersonOverPrice') * Math.max(netPeople - getPrice(price, priceBaseKey,'People'), 0)
      extraPetPrice += price.petPrice * (pets||0)
    }

    return {
      basePrice,
      extraPeoplePrice,
      extraPetPrice,
      maxPeople
    }
}

export default function RenterNumberModal(props: any) {
  const [form, setForm] = useState<any>({people: props.data?.price.normalDayPeople || 2, children: 0, pets: 0})
  const totalPeople = (form.people||0) + (form.children||0)
  const f = {...props.form, ...form}
  const {basePrice, extraPeoplePrice, extraPetPrice, maxPeople} =  getPriceFromDate(props.data?f:{}, props.data?.price)
  const totalPrice = basePrice + extraPeoplePrice + extraPetPrice

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={props.show}
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          ผู้เข้าพัก
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div className="row-edge-center mb-3">
          <div>
            <div className="text-primary">ผู้ใหญ่</div>
            <div>อายุ 15 ปีขึ้นไป</div>
          </div>
          <Counter defaultCount={form.people} min={1} max={maxPeople - form.children} onChange={(people)=>setForm({...form, people})} />
        </div>
        <div className="row-edge-center mb-3">
          <div>
            <div className="text-primary">เด็ก</div>
            <div>อายุ 7 ขวบขึ้นไป</div>
          </div>
          <Counter max={Math.min(maxPeople - form.people, maxPeople)} onChange={(children)=>setForm({...form, children})} />
        </div>
        <div className="row-edge-center">
          <div>
            <div className="text-primary">สัตว์เลี้ยง</div>
            <div>สุนัขและแมว หากเป็นสัตว์อื่น<br/>กรุณาสอบถามเจ้าของที่พัก</div>
          </div>
          <Counter onChange={(pets)=>setForm({...form, pets})} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>props.onSubmit?.(form, {basePrice, extraPeoplePrice, extraPetPrice, totalPeople})}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}

export function RenterFilterNumberModal(props: any) {
  const [form, setForm] = useState<any>(props.form)
  const totalPeople = (form.people||0) + (form.children||0)

  useEffect(()=>{
    setForm(props.form)
  }, [props.form])


  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={props.show}
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          ผู้เข้าพัก
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div className="row-edge-center mb-3">
          <div>
            <div className="text-primary">ผู้ใหญ่</div>
            <div>อายุ 15 ปีขึ้นไป</div>
          </div>
          <Counter defaultCount={form.people} min={1}  onChange={(people)=>setForm({...form, people})} />
        </div>
        <div className="row-edge-center mb-3">
          <div>
            <div className="text-primary">เด็ก</div>
            <div>อายุ 7 ขวบขึ้นไป</div>
          </div>
          <Counter defaultCount={form.children} onChange={(children)=>setForm({...form, children})} />
        </div>
        <div className="row-edge-center">
          <div>
            <div className="text-primary">สัตว์เลี้ยง</div>
            <div>สุนัขและแมว หากเป็นสัตว์อื่น<br/>กรุณาสอบถามเจ้าของที่พัก</div>
          </div>
          <Counter defaultCount={form.pets} onChange={(pets)=>setForm({...form, pets})} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>props.onSubmit?.(form)}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}
