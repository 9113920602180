import moment from 'moment';
import 'moment/locale/th';

moment.locale('th');

export const HEX2RGBA = (hex, opacity = 1) => {
        if (hex.charAt(0) === '#') {
            hex = hex.substr(1);
        }
        if ((hex.length < 2) || (hex.length > 6)) {
            return hex;
        }
        var values = hex.split(''),
            r,
            g,
            b;

        if (hex.length === 2) {
            r = parseInt(values[0].toString() + values[1].toString(), 16);
            g = r;
            b = r;
        } else if (hex.length === 3) {
            r = parseInt(values[0].toString() + values[0].toString(), 16);
            g = parseInt(values[1].toString() + values[1].toString(), 16);
            b = parseInt(values[2].toString() + values[2].toString(), 16);
        } else if (hex.length === 6) {
            r = parseInt(values[0].toString() + values[1].toString(), 16);
            g = parseInt(values[2].toString() + values[3].toString(), 16);
            b = parseInt(values[4].toString() + values[5].toString(), 16);
        } else {
            return hex;
        }
        return 'rgba('+r+','+g+','+b+','+opacity+')';
  }

export const roundSize = (size) => {
  return Number(size.toFixed(2))
}

export const getLimitedNumber = (number, limit) => {
    if (number <= limit) {
        return number;
    }

    return limit + '+';
}

export const arrayToObject = (array, key = 'value', value = 'text') => {
    var obj = {};

    if (array.length == 0) {
        return obj;
    }

    array.map((item)=>{
        obj['_preventSorting'+item[key]] = item[value];

        return item;
    });

    //This set is true value, but we will not show them.
    array.map((item)=>{
        obj[item[key]] = item[value];

        return item;
    });

    return obj;
}

export function dateRange(startDate, endDate) {
    endDate = moment(endDate)
    var now = moment(startDate), dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push(moment(now));
        now.add(1, 'days');
    }

    return dates;
};

export const checkUndefined = (object, key) => {
    return typeof object[key] != 'undefined' ?
        object[key] : [];
}

export const CEToBE = (dateString, length = 4) => {
    return dateString.replace(/[0-9]{4}/g, (a)=>(parseInt(a)+543).toString().substr(length*(-1)));
}

export const ageText = (date) =>{
    var age = ageByBirthDate(date);

    return 'อายุ ' + (age<=0 ? '-':age) + ' ปี';
}

export function ageByBirthDate(date){
    return moment().diff(date, 'years');
}

export function convertNullToEmptyString(object) {
    var newObject = {};

    for (var key in object) {
      if (object[key] === null) {
          newObject[key] = '';
      } else {
          newObject[key] = object[key];
      }
    }

    return newObject;
}

export function convertToString(object, deleteObject = false) {
    var newObject = {};

    for (var key in object) {
        if (typeof object[key] == 'object' && deleteObject) {
            delete object[key];
        } else {
            newObject[key] = object[key].toString();
        }
    }

    return newObject;
}

export function convertEmptyStringToDefault(object, v = '-') {
    var newObject = {};

    for (var key in object) {
        if (object[key] === '') {
          newObject[key] = '-';
      } else {
          newObject[key] = object[key];
      }
    }

    return newObject;
}

export function fileNameAndExt(str){
  var file = str.split('/').pop();

  return file.substr(0,file.lastIndexOf('.')) +'.'+file.substr(file.lastIndexOf('.')+1,file.length);
}

export function numberFormat(num, hideDecimalWhenInteger = false, zeroText = '-') {
    var tester = parseFloat(num);

    if (num === 0 || num === null || num === undefined || num === '') {
        return zeroText;
    }

    if (tester > Math.floor(tester) ) {
        num = tester;
        num = num.toFixed(2);
    } else if (hideDecimalWhenInteger) {
        if (num != '' && num != null){
            num = Math.floor(num);
        }
    }

    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function numberInRange(number, min, max) {
    return Math.max(Math.min(number, max), min);
}

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km

    return d;
}

export function isNearBy(lat1, lon1, lat2, lon2, dist) {
    const y1 = lon1 - dist / Math.abs(Math.cos(deg2rad(lat1)) * 69);
    const y2 = lon1 + dist / Math.abs(Math.cos(deg2rad(lat1)) * 69);
    const x1 = lat1 - (dist / 69);
    const x2 = lat1 + (dist / 69);

    return (lon2 <= y2 && lon2 >= y1) && (lat2 <= x2 && lat1 >= x1) && getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) < dist;
}

export function deg2rad(deg) {
    return deg * (Math.PI / 180)
}

export function deg2km(deg) {
    //111 is an approximate value from 110.567 km to 111.699 km
    return deg * 111;
}

export function km2deg(km) {
    return km / 111;
}

export function classNames(classes) {
  return Object.entries(classes)
    .filter(([key, value]) => value)
    .map(([key, value]) => key)
    .join(' ');
}
