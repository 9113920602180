import {useState, forwardRef, useEffect} from 'react'
import { Container, Row, Col, Button, Dropdown } from 'react-bootstrap';
import logoWhite from '../assets/images/logo-white.png';

import google from '../assets/images/google-play.png';
import apple from '../assets/images/app-store.png';
import youtube from '../assets/images/youtube.png';
import facebook from '../assets/images/facebook.png';
import line from '../assets/images/line.png';
import tiktok from '../assets/images/tiktok.png';

import LoginModal from './app/Login'
import RegisterModal from './app/Register'
import api from '../utils/Api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClockRotateLeft, faCalendarAlt, faArrowRightFromBracket, faPhone,
  faMessage, faHomeAlt, faQuestion, faHome, faCalendarCheck, faPercent, faHouseCircleCheck }
  from '@fortawesome/free-solid-svg-icons'
import {faHeart as farHeart} from '@fortawesome/free-regular-svg-icons'
import { Link } from 'react-router-dom'
import duck from '../assets/images/duck.png'
import { numberFormat } from '../utils/Functions'
// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const AvatarToggler = forwardRef(({ children, onClick, onOpen }: any, ref: any) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

function ProfileMenu(props: any) {
  const [user, setUser] = useState<any>({})

  const fetch = (params = {}) => {
    api.request('user/profile', (r: any)=>{
      setUser(r)
    }, params)
  }

  return (
    <Dropdown onToggle={(show)=>{
      if (show) {
        fetch()
      }
    }}>
      <Dropdown.Toggle as={AvatarToggler} id="menu-profile">
          <div className="menu__profile">
            <div className="menu__profile-online" />
          </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Header>
          <div className="text-dark"><strong>{user.name}</strong></div>
          <div><small>{user.type == 'lessor'?'เจ้าของที่พัก':'ผู้เช่า'}</small></div>
          <div className="menu__profile__credit row-edge-center mt-2">
            <div className="column-center">
              <div className="text-primary">TeePak</div>
              <div>เครดิต</div>
            </div>
            <div className="d-flex flex-column align-items-end">
              <div className="row-vcenter">
                <span className="me-2 text-orange">{numberFormat(user.balance?.credits, true, '0')}</span>
                <img src={duck} className="menu__profile__duck" />
              </div>
              <div><small>ไม่มีวันหมดอายุ</small></div>
            </div>
          </div>
        </Dropdown.Header>
        <Dropdown.Item href="/booking/active"><FontAwesomeIcon icon={faCalendarAlt} className="me-2" />การจองของฉัน</Dropdown.Item>
        <Dropdown.Item href="/booking/history"><FontAwesomeIcon icon={faClockRotateLeft} className="me-2"  />ประวัติการจอง</Dropdown.Item>
        <Dropdown.Item href="/coupon"><FontAwesomeIcon icon={faPercent} className="me-2"  />โค้ดส่วนลด</Dropdown.Item>
        <Dropdown.Item href="/favorite"><FontAwesomeIcon icon={farHeart} className="me-2"  />รายการโปรด</Dropdown.Item>
        { user.type === 'lessor' &&
        <>
          <Dropdown.Item href="/accommodation/create"><FontAwesomeIcon icon={faHome} className="me-2"  />เพิ่มบ้านพัก</Dropdown.Item>
          <Dropdown.Item href="/my-accommodation"><FontAwesomeIcon icon={faHouseCircleCheck} className="me-2"  />บ้านพักของฉัน</Dropdown.Item>
          <Dropdown.Item href="/manage-booking"><FontAwesomeIcon icon={faCalendarCheck} className="me-2"  />จัดการการจอง</Dropdown.Item>
        </>
        }
        <Dropdown.Item href="/credit/purchase"><img src={duck} className="menu__profile__duck me-2" />เติมเครดิต</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={()=>{
          api.request('logout', ()=>{
            props.onLogout?.();
          }, {client: 'web'}, 'POST')
          localStorage.removeItem('userKey');
          localStorage.removeItem('tokenKey');
        }}><FontAwesomeIcon className="me-2" icon={faArrowRightFromBracket} />ออกจากระบบ</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

function RightMenu() {
  return (
    <div className="row-vcenter menu__right">
      <Link to="/feed">ประกาศหาผู้เช่า</Link>
      <Link to="/feed#renter">ประกาศหาที่พัก</Link>
    </div>
  );
}

export default function Layout(props: any) {
  const u = localStorage.getItem('userKey')
  const [showLogin, setShowLogin] = useState(false)
  const [showRegister, setShowRegister] = useState(false)
  const [user, setUser] = useState(u?JSON.parse(u):null)

  const reload = (timeout = 0) => {
    if (!timeout){
       window.location.reload()
       return
    }
    setTimeout(()=>window.location.reload(), timeout)
  }

  return (
    <>
      <header style={props.headerStyle||{}} className="header">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="row-edge-center mx-3 py-3">
                <Link to="/" className="row-vcenter text-decoration-none">
                  <img className="header__logo-white" src={logoWhite} />
                  <div className="ms-2 d-none d-sm-block">
                    <div className="header__text">จองกับเจ้าของที่พักโดยตรงได้ราคาดีแน่นอน!</div>
                    <span className="header__text">Teepakhub.com</span>
                  </div>
                </Link>
                { user ?
                  <div className="row-vcenter">
                    <RightMenu />
                    <a href="/chat" className="menu__icon-container bg-primary-light rounded-circle me-2">
                      <FontAwesomeIcon icon={faMessage} />
                    </a>
                    <ProfileMenu onLogout={()=>{
                      setUser(null)
                      reload()
                    }} user={user} />
                  </div>
                  :
                  <div className="row-vcenter">
                    <RightMenu />
                    <Button className="me-2" onClick={()=>setShowRegister(true)} size="sm" variant="light">
                      สมัครสมาชิก
                    </Button>
                    <Button onClick={()=>setShowLogin(true)} size="sm" variant="light">
                      เข้าสู่ระบบ
                    </Button>
                    <LoginModal onLogin={(u: any)=>{
                      setUser(u)
                      setShowLogin(false)
                      reload(2000)
                    }} show={showLogin} onHide={()=>setShowLogin(false)} />
                    <RegisterModal
                      onLogin={(u: any)=>{
                        setUser(u)
                        setShowLogin(false)
                        reload(2000)
                      }}
                      show={showRegister} onHide={()=>setShowRegister(false)}
                     />
                  </div>
                  }
              </div>
            </Col>
          </Row>
        </Container>
      </header>
      <main>
        {props.children}
      </main>
      <footer>
        <div className="footer__content pt-3 pb-2">
          <Container>
            <Row>
              <Col xs={6} sm={8} md={6} lg={8} xl={8}>
                <Row>
                  <Col className="d-flex flex-column align-items-start" xs={12} sm={6} lg={4} xl={3}>
                    <a className="footer__quick-link link-white">บ้านพักติดทะเล</a>
                    <a className="footer__quick-link link-white">บ้านพักวิวเขา</a>
                    <a className="footer__quick-link link-white">บ้านพักมีสระเด็ก</a>
                    <a className="footer__quick-link link-white">บ้านพักรับสัตว์เลี้ยง</a>
                  </Col>
                  <Col className="d-flex flex-column align-items-start" xs={12} sm={6} lg={4} xl={3}>
                    <a className="footer__quick-link link-white">บ้านพักพัทยา</a>
                    <a className="footer__quick-link link-white">บ้านพักหัวหิน</a>
                    <a className="footer__quick-link link-white">บ้านพักเขาใหญ่</a>
                    <a className="footer__quick-link link-white">บ้านพักพูลวิลล่า</a>
                  </Col>
                  <Col className="d-flex flex-column align-items-start" xs={12} sm={6} lg={4}  xl={3}>
                    <a className="footer__quick-link link-white">บ้านพักบางแสน</a>
                    <a className="footer__quick-link link-white">บ้านพักเชียงใหม่</a>
                    <a className="footer__quick-link link-white">บ้านพักเขาค้อ</a>
                    <a className="footer__quick-link link-white">บ้านพักภูเก็ต</a>
                  </Col>
                  <Col className="d-flex flex-column align-items-start" xs={12} sm={6} lg={4} xl={3}>
                    <a className="footer__quick-link link-white">บ้านพักกรุงเทพ</a>
                    <a className="footer__quick-link link-white">Pool Villa</a>
                    <a className="footer__quick-link link-white">พูลวิลล่า</a>
                    <a className="footer__quick-link link-white">ที่พักตากอากาศ</a>
                  </Col>
                </Row>
              </Col>
              <Col xs={6} sm={4} md={6} lg={4} xl={4}>
                <Row>
                  <Col className="d-flex flex-column align-items-start" xs={12} sm={12} md={6}>
                    <a href="/" className="footer__quick-link link-white"><FontAwesomeIcon icon={faHomeAlt} />{" "}หน้าแรก</a>
                    <a href="#" className="footer__quick-link link-white"><FontAwesomeIcon icon={faMessage} />{" "}แนะนำการใช้งาน</a>
                    <a href="#" className="footer__quick-link link-white"><FontAwesomeIcon icon={faQuestion} />{" "}คำถามที่พบบ่อย</a>
                    <a href="https://page.line.me/088qzlsm" target="_blank" className="footer__quick-link link-white">
                    <FontAwesomeIcon icon={faPhone} />{" "}ติตต่อเรา</a>
                  </Col>
                  <Col className="d-flex flex-column" xs={12} sm={12} md={6}>
                    <img className="mb-1" src={google} style={{width: '100%', maxWidth: 200}} />
                    <img src={apple} style={{width: '100%', maxWidth: 200}}  />
                    <div className="w-100 row-edge-center mt-2">
                      <a className="footer__social-item"><img src={facebook} style={{width: '100%'}}  /></a>
                      <a href="https://page.line.me/088qzlsm" target="_blank" className="footer__social-item"><img src={line} style={{width: '100%'}}  /></a>
                      <a className="footer__social-item"><img src={youtube} style={{width: '100%'}}  /></a>
                      <a className="footer__social-item"><img src={tiktok} style={{width: '100%'}}  /></a>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="footer__copyright px-3 row-edge-center py-2">
          <span className="text-light">© 2023 Teepak Hub All Rights Reserved</span>
        </div>
      </footer>
    </>
  )
}
