import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Badge, Card, Nav } from 'react-bootstrap';
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import {Layout, NoResult} from '../components'
import {AccommodationItem} from '../components/app/Accommodation'
import {BookingItem} from '../components/app/Booking'
import api from '../utils/Api'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClockRotateLeft, faCalendarAlt, faSearch } from '@fortawesome/free-solid-svg-icons'
import {LessorWithMenu} from '../components/app/LessorMenu'

export default function BookingList() {
  const routeParams = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [paginate, setPaginate] = useState({data: [], page: 1, meta: {total: null}})

  const fetch = (params = {}) => {
    api.request('booking', (r: any)=>{
      setPaginate(r)
    }, {myBooking: true, pending: routeParams.state === 'active', ...params})
  }
  useEffect(()=>{
    fetch()
  }, [])

  const { data, meta } = paginate

  return (
    <Layout>
      <Container className="my-3">
        <LessorWithMenu>
        <h4 className="pb-3">{routeParams.state==='active'?'การจองของฉัน':'ประวัติการจอง'}</h4>
          {
            paginate.meta.total === 0 &&
            <NoResult title="ยังไม่มีข้อมูลรายการจอง"
              content="สามารถค้นหาที่พักได้โดยการกดปุ่มด้านล่าง"
              actionButton={<Button onClick={()=>navigate('/')}>
                <FontAwesomeIcon className="me-2" icon={faSearch} />ค้นหาเลย
              </Button>}
            />
          }
          <Row>
            { paginate.data.map((item: any, index)=>(
              <Col lg={6} md={12} key={index} className="mb-3"><BookingItem onUpdate={()=>{
                fetch()
              }} item={item} /></Col>
            ))
            }
          </Row>
        </LessorWithMenu>
      </Container>
    </Layout>
  )
}
