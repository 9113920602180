import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Badge, Card, Nav } from 'react-bootstrap';
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import {Layout, NoResult} from '../components'
import {AccommodationItem} from '../components/app/Accommodation'
import {CouponItem} from '../components/app/Coupon'
import api from '../utils/Api'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClockRotateLeft, faCalendarAlt, faSearch } from '@fortawesome/free-solid-svg-icons'
import {LessorWithMenu} from '../components/app/LessorMenu'

export default function MyCoupon() {
  const routeParams = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [paginate, setPaginate] = useState({data: [], page: 1, meta: {total: null}})

  const fetch = (params = {}) => {
    api.request('coupon', (r: any)=>{
      setPaginate(r)
    }, params)
  }

  useEffect(()=>{
    fetch()
  }, [])

  const { data, meta } = paginate

  return (
    <Layout>
      <Container className="my-3">
        <LessorWithMenu>
        <h4 className="pb-3">โค้ดส่วนลด</h4>
        <div className="bg-light px-3 py-2 mb-3 rounded">
        คุณสามารถรับโค้ดส่วนลดได้จาก
          <ul>
            <li>กิจกรรมในช่องทาง Social Media</li>
            <li>โปรโมชั่นพิเศษ/วาระพิเศษ</li>
            <li>การออกงานแฟร์ ของเรา</li>
          </ul>
        </div>
          {
            paginate.meta.total === 0 &&
            <NoResult title="ไม่พบข้อมูล"
              content=""
            />
          }
          <Row>
            { paginate.data.map((item: any, index)=>(
              <Col lg={6} xl={4} md={12} key={index} className="mb-3">
                <CouponItem onUpdate={()=>{
                  fetch()
                }} item={item} />
              </Col>
            ))
            }
          </Row>
        </LessorWithMenu>
      </Container>
    </Layout>
  )
}
