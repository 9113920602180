import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Badge } from 'react-bootstrap';
import {Layout} from '../../components'
import {AccommodationItem} from '../../components/app/Accommodation'
import {SearchFilter} from '../../components/app/Home'
import api from '../../utils/Api'
import { numberFormat } from '../../utils/Functions'
import _ from 'lodash'
import logo from '../../assets/images/logo.png'
import duck from '../../assets/images/duck.png'
import { toast } from 'react-toastify';
import PaymentModal from './creditPurchase/PaymentModal'
import { useSearchParams } from 'react-router-dom'

const credits = [{
  label: '50 บาท',
  value: 50
},{
  label: '100 บาท',
  value: 100
},{
  label: '300 บาท',
  value: 300
},{
  label: '500 บาท',
  value: 500
},]

export default function CreditPurchase() {
  const [user, setUser] = useState<any>({})
  const [purchasingItem, setPurchasingItem] = useState<{label: string, value: number}|null>(null)
  const [showPayment, setShowPayment] = useState<boolean>(false)
  const [searchParams] = useSearchParams()

  const resultCode = searchParams.get('resultCode')
  const resultMessage = searchParams.get('resultMessage')

  const fetch = (params = {}, cb = (r: any)=>{}) => {
    api.request('user/profile', (r: any)=>{
      cb(r)
      setUser(r)
    }, params)
  }

  const autoCheckCredits = () => {
    fetch({}, (r: any)=>{
      if (r.balance?.credits > user.balance?.credits) {
        setShowPayment(false)
        toast.success('เติมเครดิตสำเร็จ')
      }
    })
  }

  useEffect(()=>{
    fetch()
  }, [])

  useEffect(()=>{
    if (!resultCode) return

    if (resultCode === '00') {
      toast.success('เติมเครดิตสำเร็จ')
    } else {
      toast.error(resultMessage)
    }
  }, [resultCode])

  useEffect(()=>{
    const checker = setInterval(autoCheckCredits, 5000)

    return ()=>{
      clearInterval(checker)
    }
  }, [user])


  return (
    <Layout>
      <Container className="py-3">
        <Row>
          <Col xs={12} lg={6} className="mb-4">
            <div className="credit-member-card__container">
              <div className="credit-member-card__content">
                <div>
                  <img className="credit-member-card__logo" src={logo} />
                  <div><span className="text-primary">TeePak</span> เครดิต</div>
                </div>
                <div className="row-edge-center mt-4">
                  <div className="row-vcenter">
                    <strong className="text-orange fs-2 me-3">{numberFormat(user?.balance?.credits, true, '0')}</strong>
                    <img src={duck} className="credit-member-card__duck" />
                  </div>
                  <div className="text-muted">ไม่มีวันหมดอายุ</div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <h1>เติมเครดิต</h1>
            <div className="credit-list mt-4">
              {credits.map(({label, value}: any, index: number)=>(
                <div key={index}
                className="credit-list__item shadow row-edge-center py-3 px-3">
                  <div>
                    <strong className="fs-4">{label}</strong>
                  </div>
                  <div className="row-vcenter">
                    <div className="row-vcenter me-3">
                      <strong className="fs-4 text-orange me-2">ได้รับ {value}</strong>
                      <img src={duck} className="credit-list__duck" />
                    </div>
                    <Button onClick={()=>{
                      setPurchasingItem({label, value})
                      setShowPayment(true)
                      // api.request('credit', (r,s)=>{
                      //   toast.success('เติมเครดิตสำเร็จ')
                      //   setUser(r)
                      // }, api.form({credit: value}), 'POST')
                    }}>เติมเครดิต</Button>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
      <PaymentModal onHide={()=>setShowPayment(false)}
      onFinish={()=>{
        fetch({}, (r: any)=>{
          if (r.balance?.credits > user.balance?.credits) {
            toast.success('เติมเครดิตสำเร็จ')
          }
        })
        setShowPayment(false)
      }}
      show={showPayment} item={purchasingItem} />
    </Layout>
  )
}
