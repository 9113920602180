import {useState, useEffect} from 'react'
import { Container, Row, Col, Button, Modal, Carousel, Form } from 'react-bootstrap';
import renter from '../../assets/images/renter.png'
import lessor from '../../assets/images/lessor.png'
import logo from '../../assets/images/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import api from '../../utils/Api'

export default function LoginModal(props: any) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [isLessor, setIsLessor] = useState(false)
  const [form, setForm] = useState({mobile: '', password: ''})

  useEffect(()=>{
    if (props.show) return

    setTimeout(()=>setActiveIndex(0), 300)
  }, [props.show])

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={props.show}
      onHide={props.onHide}
    >
      <Modal.Header closeButton />
      <Modal.Body className="login">
        <Carousel
        slide={false}
        interval={null}
        keyboard={false}
        controls={false}
        touch={false}
        indicators={false}
        activeIndex={activeIndex}
        >
          <Carousel.Item>
            <div className="column-center mb-3">
              <img src={logo} className="login__logo" />
              <div className="mt-2">โปรดเลือกประเภทสมาชิก</div>
            </div>
            <div onClick={()=>{
              setIsLessor(false)
              setActiveIndex(1)
            }} className="login__selector shadow">
              <div>
                <img src={renter} />
                <strong>ผู้เช่า หรือ นายหน้า</strong>
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
            <div onClick={()=>{
              setIsLessor(true)
              setActiveIndex(1)
            }} className="login__selector shadow">
              <div>
                <img src={lessor} />
                <strong>เจ้าของที่พัก หรือ ตัวแทน</strong>
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </Carousel.Item>
          <Carousel.Item className="px-3">
            <div className="column-center mb-3">
              <img src={logo} className="login__logo" />
              <div className="mt-2">เข้าสู่ระบบ</div>
              <small>สำหรับ{isLessor?'เจ้าของที่พัก หรือ ตัวแทน':'ผู้เช่า หรือ นายหน้า'}</small>
            </div>
            <Form.Control maxLength={10}
             onChange={(e)=>setForm({...form, mobile: e.target.value})} placeholder={'กรอกเบอร์โทร'} />
            <Button onClick={()=>{
              if (!form.mobile) {
                toast.warn('กรุณากรอกเบอร์โทร')
                return
              }
              setActiveIndex(2)
            }} className="w-100 mt-3">ดำเนินการต่อ</Button>
            <div className="row-center mt-1 cursor-pointer" onClick={()=>setActiveIndex(0)}>
              <FontAwesomeIcon icon={faArrowLeft} /><span className="ms-2">ย้อนกลับ</span>
            </div>
          </Carousel.Item>
          <Carousel.Item className="px-3">
            <div className="column-center mb-3">
              <img src={logo} className="login__logo" />
              <div className="mt-2">กรอกรหัส{isLessor?' OTP':'ผ่าน'}</div>
              <small>สำหรับ{isLessor?'เจ้าของที่พัก หรือ ตัวแทน':'ผู้เช่า หรือ นายหน้า'}</small>
            </div>
            <Form.Control
             type="password"
             onChange={(e)=>setForm({...form, password: e.target.value})}
             placeholder={'กรุณากรอกรหัส'+(isLessor?' OTP ที่ได้รับจาก SMS':'ผ่าน')} />
            <Button onClick={()=>{
              if (!form.password) {
                toast.warn('กรุณากรอกรหัส')
                return
              }

              api.request('login', ({user, token}, s)=>{
                if (!s) return
                localStorage.setItem('userKey', JSON.stringify(user))
                localStorage.setItem('tokenKey', token.token)
                if (props.onLogin) {
                  props.onLogin(user, token)
                }
                toast.success('เข้าสู่ระบบสำเร็จ')
                setTimeout(()=>{
                  window.location.reload()
                }, 1000)
              }, api.form({...form, otp: isLessor?form.password:null, type: isLessor?'lessor':'renter'}), 'POST')
            }} className="w-100 mt-3">เข้าสู่ระบบ</Button>
            <div className="row-center mt-1 cursor-pointer" onClick={()=>setActiveIndex(1)}>
              <FontAwesomeIcon icon={faArrowLeft} /><span className="ms-2">ย้อนกลับ</span>
            </div>
          </Carousel.Item>
        </Carousel>
      </Modal.Body>
    </Modal>
  )
}
