import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Badge, Card, Nav, Carousel, Form, Modal } from 'react-bootstrap';
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import {Layout, NoResult} from '../components'
import {AccommodationItem} from '../components/app/Accommodation'
import {BookingItem} from '../components/app/Booking'
import api from '../utils/Api'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { toast } from 'react-toastify';
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faArrowRight, faArrowLeft, faMapLocationDot, faImage, faUpload, faCheckCircle} from '@fortawesome/free-solid-svg-icons'
import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons'
import {LessorWithMenu} from '../components/app/LessorMenu'
import {reactSelectValue} from '../utils/Helpers'
import {numberFormat} from '../utils/Functions'
import Select from 'react-select';
import Map from '../components/Map'
import Counter from '../components/app/Counter'
import GalleryUploader from '../components/GalleryUploader'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const regions = [
  {
    value: 'c',
    label: 'ภาคกลาง',
  },
  {
    value: 'e',
    label: 'ภาคตะวันออก',
  },
  {
    value: 'n',
    label: 'ภาคตะวันเหนือ',
  },
  {
    value: 's',
    label: 'ภาคตะวันใต้',
  },
  {
    value: 'w',
    label: 'ภาคตะวันตก',
  },
  {
    value: 'ne',
    label: 'ภาคอีสาน',
  },
]

const dateTypes = [
  {
    value: 'normalDay',
    label: 'วันจันทร์ - พฤหัสบดี',
  },
  {
    value: 'friday',
    label: 'วันศุกร์',
  },
  {
    value: 'saturday',
    label: 'วันเสาร์',
  },
  {
    value: 'sunday',
    label: 'วันอาทิตย์',
  },
  {
    value: 'holiday',
    label: 'วันหยุดนักขัตฤกษ์',
  },
  {
    value: 'vacation',
    label: 'วันหยุดยาว/ปีใหม่/สงกรานต์',
  },
]

export default function CreateAccommodation() {
  const routeParams = useParams()
  const navigate = useNavigate()
  const [activeIndex, setActiveIndex] = useState(0)
  const [options, setOptions] = useState<any>({location: []})
  const [form, setForm] = useState<any>({ tagIds: [], isJoinedDiscount: true })
  const [price, setPrice] = useState<any>({})
  const [showMap, setShowMap] = useState(false)
  const [showPrice, setShowPrice] = useState(false)
  const [currentEditPrice, setCurrentEditPrice] = useState<any>({})
  const [images, setImages] = useState<any>(null)
  const [image, setImage] = useState<any>(null)
  const [tags, setTags] = useState<{[key: string]: []}>({
    'popular': [],
    'featured': [],
    'facility': [],
    'electric': [],
    'activity': [],
    'style': []
  })

  const onSelectChange = ({value}: any, action: any)=>{
    setForm({...form, [action.name]: value})
  }

  const setTagIds = (checked: boolean, id: number) => {
      let ids = [...form.tagIds]
      const index = ids.indexOf(id)
      if (index > -1 && !checked) {
        ids.splice(index, 1)
      } else if (checked) {
        ids.push(id)
      }

      setForm({...form, tagIds: ids})
  }

  const fetchLocations = (params = {}) => {
    api.request('location', (res)=>{
      setOptions({location: res.map(({id, name}: any)=>({value:id,label:name}))})
    })
  }


    const fetchTags = () => {
      api.request('tag', (data)=>{
        const _tags = {...tags}

        for (let k in tags) {
          const key:string = k
          _tags[key] = data.filter((props:{type: string})=>props.type === k).map((props: {id: number, name: string})=>
          ({name: props.name, id: props.id}))
        }

        setTags(_tags)
      })
    }

  useEffect(()=>{
    fetchLocations()
    fetchTags()
  }, [])

  return (
    <Layout>
      <Container className="my-3">
      <LessorWithMenu>
        <h4>{'เพิ่มบ้านพัก'}</h4>
        <hr />
        <Carousel
        slide={false}
        interval={null}
        keyboard={false}
        controls={false}
        touch={false}
        indicators={false}
        activeIndex={activeIndex}
        >
          <Carousel.Item>
            <h5 className="mb-4">ข้อมูลหลัก</h5>
            <Row>
              <Col xs={12}>
                <Form.Control onChange={(e)=>setForm({...form, name: e.target.value})} placeholder="ชื่อบ้านพัก" />
              </Col>
              <Col md={6} sm={12} className="mt-3">
                <Select
                  classNamePrefix="react-select"
                  //styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  placeholder="พื้นที่"
                  options={options.location}
                  name="locationId"
                  onChange={onSelectChange}
                  value={reactSelectValue(options.location, form.locationId)}
                />
              </Col>
              <Col md={6} sm={12} className="mt-3">
                <Select
                  classNamePrefix="react-select"
                  //styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  placeholder="ภาค"
                  options={regions}
                  name="region"
                  onChange={onSelectChange}
                  value={reactSelectValue(regions, form.region)}
                />
              </Col>
              <Col md={6} sm={12} className="mt-3">
                <Form.Control onChange={(e)=>setForm({...form, nearbyPlaces: e.target.value})} placeholder="สถานที่ใกล้เคียง(ถ้ามี)" />
              </Col>
              <Col md={6} sm={12} className="mt-3">
                <div className="position-relative" onClick={()=>setShowMap(true)}>
                  <Form.Control
                  readOnly
                   value={form.lat?(form.lat + ', ' + form.lon):''} placeholder="พิกัดที่ตั้ง Google map" />
                  <div className="input-icon">
                    <FontAwesomeIcon
                      icon={faMapLocationDot}
                    />
                   </div>
                </div>
              </Col>
            </Row>
            <h5 className="my-4">จำนวนห้อง</h5>
            <Row className="room-container">
              <Col xs={12} sm={6} className="mb-3">
                <span className="unselectable">จำนวนห้องเริ่มต้น</span>
                <Counter defaultCount={form.minRooms} onChange={(minRooms: any)=>setForm({...form, minRooms})} />
              </Col>
              <Col xs={12} sm={6} className="mb-3">
                <span className="unselectable">จำนวนห้องสูงสุด</span>
                <Counter defaultCount={form.maxRooms} onChange={(maxRooms: any)=>setForm({...form, maxRooms})} />
              </Col>
              <Col xs={12} sm={6} className="mb-3">
                <span className="unselectable">จำนวนห้องน้ำเริ่มต้น</span>
                <Counter defaultCount={form.minBathrooms} onChange={(minBathrooms: any)=>setForm({...form, minBathrooms})} />
              </Col>
              <Col xs={12} sm={6} className="mb-3">
                <span className="unselectable">จำนวนห้องน้ำสูงสุด</span>
                <Counter defaultCount={form.maxBathrooms} onChange={(maxBathrooms: any)=>setForm({...form, maxBathrooms})} />
              </Col>
              <Col xs={12} sm={6} className="mb-3">
                <span className="unselectable">จำนวนเตียงเริ่มต้น</span>
                <Counter defaultCount={form.minBeds} onChange={(minBeds: any)=>setForm({...form, minBeds})} />
              </Col>
              <Col xs={12} sm={6} className="mb-3">
                <span className="unselectable">จำนวนเตียงสูงสุด</span>
                <Counter defaultCount={form.maxBeds} onChange={(maxBeds: any)=>setForm({...form, maxBeds})} />
              </Col>
              <Col xs={12}>
                <Form.Group className="w-100">
                  <Form.Label>รายละเอียดเพิ่มเติม(ถ้ามี)</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="รายละเอียดเช่น จำนวน/ขนาดเตียง และห้องน้ำ ของแต่ละห้อง"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} className="d-flex justify-content-end mt-3">
                <Button onClick={()=>setActiveIndex(1)}>ถัดไป<FontAwesomeIcon className="ms-2" icon={faArrowRight} /></Button>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
          <Row>
            <Col xs={12}>
              <h5 className="mb-3">จุดเด่น</h5>
            </Col>
            { tags.featured.map(({id, name})=>(
              <Col md={4} lg={3} xs={6} key={id} className="mb-2">
              <Form.Check
                  type={'checkbox'}
                  id={`tag-${id}`}
                  label={name}
                  onChange={(e)=>setTagIds(e.target.checked, id)}
                  checked={form.tagIds.indexOf(id) > -1}
                />
              </Col>
            ))
           }
           <Col xs={12}>
              <hr />
             <h5 className="mb-3">สไตล์บ้านพัก</h5>
           </Col>
           { tags.style.map(({id, name})=>(
             <Col md={4} lg={3} xs={6} key={id} className="mb-2">
             <Form.Check
                 type={'checkbox'}
                 id={`tag-${id}`}
                 label={name}
                 onChange={(e)=>setTagIds(e.target.checked, id)}
                 checked={form.tagIds.indexOf(id) > -1}
               />
             </Col>
           ))
          }
          <Col xs={12}>
             <hr />
            <h5 className="mb-3">กิจกรรม</h5>
          </Col>
          { tags.activity.map(({id, name})=>(
            <Col md={4} lg={3} xs={6} key={id} className="mb-2">
            <Form.Check
                type={'checkbox'}
                id={`tag-${id}`}
                label={name}
                onChange={(e)=>setTagIds(e.target.checked, id)}
                checked={form.tagIds.indexOf(id) > -1}
              />
            </Col>
           ))
          }
          <Col xs={12}>
             <hr />
            <h5 className="mb-3">เครื่องใช้ไฟฟ้า</h5>
          </Col>
          { tags.electric.map(({id, name})=>(
            <Col md={4} lg={3} xs={6} key={id} className="mb-2">
            <Form.Check
                type={'checkbox'}
                id={`tag-${id}`}
                label={name}
                onChange={(e)=>setTagIds(e.target.checked, id)}
                checked={form.tagIds.indexOf(id) > -1}
              />
            </Col>
           ))
          }
          <Col xs={12}>
             <hr />
            <h5 className="mb-3">สิ่งอำนวยความสะดวก อื่นๆ</h5>
          </Col>
          { tags.facility.map(({id, name})=>(
            <Col md={4} lg={3} xs={6} key={id} className="mb-2">
            <Form.Check
                type={'checkbox'}
                id={`tag-${id}`}
                label={name}
                onChange={(e)=>setTagIds(e.target.checked, id)}
                checked={form.tagIds.indexOf(id) > -1}
              />
            </Col>
          ))
          }
            <Col xs={12}>
              <hr />
            </Col>
            <Col xs={12} className="row-edge-center mt-3">
              <Button variant="secondary" onClick={()=>setActiveIndex(0)}><FontAwesomeIcon className="me-2" icon={faArrowLeft} />ย้อนกลับ</Button>
              <Button onClick={()=>setActiveIndex(2)}>ถัดไป<FontAwesomeIcon className="ms-2" icon={faArrowRight} /></Button>
            </Col>
          </Row>
          </Carousel.Item>
          <Carousel.Item>
            <h5 className="mb-4">ข้อมูลราคา</h5>
            {
              dateTypes.map((item, index)=>(
                <div key={index}
                onClick={()=>{
                    setCurrentEditPrice(item)
                    setShowPrice(true)
                }}
                className="row-edge-center shadow p-3 m-3 rounded cursor-pointer">
                  <div className="d-flex flex-column">
                    <strong className="text-primary">{item.label}</strong>
                    <span>  {`${numberFormat(price[item.value+'Price'], true)} บาท / ${price[item.value+'People'] ?? '-'} คน`}</span>
                  </div>
                  <FontAwesomeIcon className="text-primary" icon={faPen} />
                </div>
              ))
            }
            <Row>
              <Col xs={12}>
                <hr />
              </Col>
              <Col xs={12} sm={6} lg={4} className="column-center mb-3">
                <div>
                  <small>สัตว์เลี้ยงคิดราคาเพิ่ม</small>
                  <small>(ต่อตัว)</small>
                </div>
                <Counter step={100} onChange={(petPrice)=>setPrice({...price, petPrice})} isPrice enableInput />
              </Col>

              <Col xs={12} sm={6} lg={4} className="border-start border-end column-center mb-3">
                <span className="mb-2">เด็กอายุไม่เกิน (ขวบ)</span>
                <Counter onChange={(noChargeChildAge)=>setPrice({...price, noChargeChildAge})} isPrice enableInput />
              </Col>
              <Col xs={12} sm={6} lg={4} className="column-center mb-3">
                <span className="mb-2">พักฟรีได้ไม่เกิน (คน)</span>
                <Counter onChange={(noChargeChildNumber)=>setPrice({...price, noChargeChildNumber})} isPrice enableInput />
              </Col>
              <Col xs={12}><hr/></Col>
            </Row>
            <Row>
              <Col xs={12}>
                <h5 className="mb-3">รายละเอียดเพิ่มเติม/ข้อห้าม</h5>
              </Col>
            </Row>
            <Row className="room-container">
              <Col xs={12} sm={6} className="mb-3">
                <span className="unselectable">ค่ามัดจำ</span>
                <Counter step={500} onChange={(downPayment)=>setPrice({...price, downPayment})} isPrice enableInput />
              </Col>
              <Col xs={12} sm={6} className="mb-3">
                <span className="unselectable">ค่าประกันของเสียหาย</span>
                <Counter step={500} onChange={(insurancePrice)=>setPrice({...price, insurancePrice})} isPrice enableInput />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="รายละเอียดเพิ่มเติม/ข้อห้าม"
                  onChange={(e)=>setForm({...form, additionalDesc: e.target.value})}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}><hr/></Col>
              <Col sm={12} md={6} className="mb-3">
                <h5 className="mb-3">เวลาเช็คอิน-เอาท์</h5>
                <div className="d-flex">
                  <div className="d-flex flex-column mb-3 me-3">
                    <span className="mb-2">เช็คอิน</span>
                    <TimePicker
                      value={form.checkIn}
                      onChange={(checkIn) => setForm({...form, checkIn})}
                    />
                  </div>
                  <div className="d-flex flex-column mb-3">
                    <span className="mb-2">เช็คเอาท์</span>
                    <TimePicker
                      value={form.checkOut}
                      onChange={(checkOut) => setForm({...form, checkOut})}
                    />
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6}>
                  <h5 className="mb-3">ข้อมูลสำหรับติดต่อ</h5>
                  <Form.Control
                    placeholder="เบอร์โทรศัพท์"
                    className="mb-3"
                    onChange={(e)=>setForm({...form, mobile: e.target.value})}
                  />
                  <Form.Control
                    placeholder="Line ID"
                    onChange={(e)=>setForm({...form, line: e.target.value})}
                  />
              </Col>
              <Col xs={12} className="row-edge-center mt-3">
                <Button variant="secondary" onClick={()=>setActiveIndex(1)}><FontAwesomeIcon className="me-2" icon={faArrowLeft} />ย้อนกลับ</Button>
                <Button onClick={()=>setActiveIndex(3)}>ถัดไป<FontAwesomeIcon className="ms-2" icon={faArrowRight} /></Button>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <div className="uploader mb-4">
              <div className="uploader__input bg-light border-primary" style={{width: '100%', borderWidth: 2, borderStyle: 'dashed',
              height: 330}}>
                { image ?
                  <div className="image-contain" style={{backgroundImage: `url(${URL.createObjectURL(image)})`, width: 300, height: 300}} />
                  :
                <>
                  <FontAwesomeIcon size="3x" className="text-secondary" icon={faImage} />
                  <span className="mt-2 text-primary"><FontAwesomeIcon icon={faUpload} className="me-2" />อัปโหลดรูปภาพหน้าปก 1 ภาพ</span>
                </>
                }
                <input type="file" onChange={(e)=> {
                  setImage(e.target.files?.[0]);
                }} />
              </div>
            </div>
            <h5>อัปโหลดรูปภาพรายละเอียด</h5>
            <div className="mb-3"><span className="text-secondary">ไม่เกิน 100 รูปภาพ</span></div>
            <GalleryUploader onChange={(images)=> setImages(images.length?images:null)} />
            <Col xs={12} className="row-edge-center mt-4">
              <Button variant="secondary" onClick={()=>setActiveIndex(2)}><FontAwesomeIcon className="me-2" icon={faArrowLeft} />ย้อนกลับ</Button>
              <Button onClick={()=>setActiveIndex(4)}>ถัดไป<FontAwesomeIcon className="ms-2" icon={faArrowRight} /></Button>
            </Col>
          </Carousel.Item>
          <Carousel.Item>
            <h5 className="mb-3">โค้ดส่วนลด</h5>
            <p>เพื่อเป็นการ <span className="text-primary">เพิ่มโอกาสในการจอง</span> ที่พักของท่าน ทาง TeePak Hub จะมีโครงการออกบูธในงานท่องเที่ยวและร่วมมือกับพาร์ทเนอร์จากบริษัทชั้นนำของประเทศ เช่น กลุ่มท่องเที่ยว กลุ่มธนาคาร กลุ่มสื่อสาร เป็นต้น รวมถึงการโปรโมทในช่องทางการตลาดของ
            TeePak Hub โดยจะมีการให้โค้ดส่วนลดกับผู้เช่า ตามกิจกรรม/เงื่อนไข/โปรโมชั่น ที่กำหนด</p>
            <Row>
              <Col md={6} sm={12} className="mb-3">
                <div onClick={()=>setForm({...form, isJoinedDiscount: true})} className={"row-vcenter d-inline-flex bg-"+(form.isJoinedDiscount?'primary-light':'white')+" border border-"+ (form.isJoinedDiscount?'primary':'secondary') +" rounded p-2 cursor-pointer"}>
                  <FontAwesomeIcon className={"me-2 text-" + (form.isJoinedDiscount?'primary':'secondary')} size="lg" icon={form.isJoinedDiscount?faCheckCircle:farCircle} />
                  <span>บ้านหลังนี้ <span className="text-primary">สนใจ</span> เข้าร่วมโครงการโค้ดส่วนลด 5-10%</span>
                </div>
              </Col>
              <Col md={6} sm={12} className="mb-3">
                <div onClick={()=>setForm({...form, isJoinedDiscount: false})} className={"row-vcenter d-inline-flex border bg-"+(!form.isJoinedDiscount?'primary-light':'white')+" border-"+ (!form.isJoinedDiscount?'primary':'secondary') +" rounded p-2 cursor-pointer"}>
                  <FontAwesomeIcon className={"me-2 text-" + (!form.isJoinedDiscount?'primary':'secondary')} size="lg" icon={!form.isJoinedDiscount?faCheckCircle:farCircle} />
                  <span>บ้านหลังนี้ <span className="text-primary text-decoration-underline">ไม่สนใจ</span> เข้าร่วมโครงการโค้ดส่วนลด 5-10%</span>
                </div>
              </Col>
              <Col xs={12} className="row-edge-center mt-4">
                <Button variant="secondary" onClick={()=>setActiveIndex(3)}><FontAwesomeIcon className="me-2" icon={faArrowLeft} />ย้อนกลับ</Button>
                <Button onClick={()=>{
                  api.request('accommodation', (r, s)=>{
                      if (!s) return

                      toast.success('เพิ่มที่พักสำเร็จ')
                      navigate('/accommodation/' + r.id)
                  }, api.form({...(_.omit(form, 'tagIds')), checkIn: form.checkIn?.format('HH:mm'), checkOut: form.checkOut?.format('HH:mm'), 
                  tags: form.tagIds, price}, {coverImage: image, gallery: images}), 'POST')
                }}>เพิ่มที่พัก</Button>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </LessorWithMenu>
      </Container>
      <Modal size="lg" centered show={showMap} onHide={()=>setShowMap(false)}>
        <Modal.Body>
          <Map
          position={form.lat?{lat:form.lat, lng: form.lon}:undefined}
          onChangeLocation={({lat,lng}: any)=>{
            setForm({...form, lat, lon: lng})
          }} />
        </Modal.Body>
      </Modal>
      <Modal centered show={showPrice} onHide={()=>setShowPrice(false)}>
        <Modal.Body>
        <strong className="text-primary">{currentEditPrice.label}</strong>
        <div className="row-edge-center mt-3 flex-wrap">
          <div className="room-item">
            <small className="mb-2">จำนวนคนเข้าพักเริ่มต้น</small>
            <Counter isPrice onChange={(c)=>setPrice({...price, [currentEditPrice.value+'People']: c})} defaultCount={form[currentEditPrice.value+'People']} enableInput />
          </div>
          <div className="room-item">
            <small className="mb-2">รองรับคนเข้าพักสูงสุด</small>
            <Counter isPrice onChange={(c)=>setPrice({...price, [currentEditPrice.value+'MaxPeople']: c})} defaultCount={form[currentEditPrice.value+'MaxPeople']} enableInput />
          </div>
          <div className="room-item">
            <small className="mb-2">ราคาที่พัก</small>
            <Counter step={500} isPrice onChange={(c)=>setPrice({...price, [currentEditPrice.value+'Price']: c})} defaultCount={form[currentEditPrice.value+'Price']} enableInput />
          </div>
          <div className="room-item">
            <small className="mb-2">เกินจำนวน คิดราคาเพิ่ม/คน</small>
            <Counter step={100} isPrice onChange={(c)=>setPrice({...price, [currentEditPrice.value+'PersonOverPrice']: c})} defaultCount={form[currentEditPrice.value+'PersonOverPrice']} enableInput />
          </div>
        </div>
        <div className="mt-3 row-center"><Button onClick={()=>setShowPrice(false)}>บึนทึกข้อมูล</Button></div>
        </Modal.Body>
      </Modal>
    </Layout>
  )
}
