import {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCamera } from '@fortawesome/free-solid-svg-icons'
import logoGray from '../assets/images/logo-gray.png'

export default function NoResult({title, content, actionButton}:
  {title?: string, content?: string, actionButton?: any}) {

  return (
    <div className="column-center">
      <img src={logoGray} style={{width: 140}} />
      <h5 className="mt-3">{title||'ไม่พบข้อมูล'}</h5>
      <p className="text-muted text-center">{content|| 'Sorry, nothing found here 🙁'}</p>
      {actionButton}
    </div>
  )
}
