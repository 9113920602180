import axios from 'axios';
import { toast } from 'react-toastify';
import React, { useRef, useEffect, useCallback } from 'react'
import moment from 'moment'
const CancelToken = axios.CancelToken;

let baseAdminDomain = global.window && window.location.host.toString();
export const __isDebug = !baseAdminDomain.match(/teepakhub\.com/)
const scheme = __isDebug?'http://':'https://';
const wsScheme = __isDebug?'ws://':'wss://';

let baseDomain;
switch (baseAdminDomain) {
  case "teepakhub.com":
  case "www.teepakhub.com":
    baseDomain = "api.teepakhub.com";
    break;
    case "127.0.0.1:3000":
      baseDomain = "127.0.0.1:3333";
    break;
    case "localhost:3000":
      baseDomain = "localhost:3333";
      break;
  default:
    baseDomain = "192.168.1.34:3333";
    // baseAdminDomain = "192.168.1.34:3000";
    break;
}

const baseUrl = scheme+baseDomain
const baseApiUrl = baseUrl;
const staticAssetUrl = baseApiUrl + '/';
const baseAdminUrl = scheme+baseAdminDomain

const requester = axios.create({
  baseURL: baseApiUrl
});

export const showCommonError = function (error) {
    if (error.response && error.response.data) {
        var msg;
        switch (error.response.status) {
            case 401:
                msg = 'รหัสผ่าน/การเข้าสู่ระบบผิดพลาด';
            break;
            case 403:
                msg = 'ขออภัย คุณไม่ได้รับสิทธิการเข้าถึงข้อมูลนี้';
            break;
            case 404:
                msg = 'ไม่พบข้อมูล';
            break;
            //case 500:
                //msg = 'เกิดข้อผิดพลาดเซิฟเวอร์ กรุณาติดต่อเจ้าหน้าที่';
            //break;
            default:
                if (error.response.data.error) {
                    msg = error.response.data.error.message;
                } else {
                    msg = error.response.data.message;
                }
            break;
        }

        if (error.response.status != 422)
            toast.error(msg.replace('\r\n', ', '))
        else {
            toast.warn(msg.replace('\r\n', ', '))
          }
    }
};

const objectToFormData = function(obj, form, namespace) {

  var fd = form || new FormData();
  var formKey;

  for(var property in obj) {
    if(obj.hasOwnProperty(property)) {

      if(namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (obj[property] instanceof Date || obj[property] instanceof moment) {
        fd.append(formKey, moment(obj[property]).toISOString());
      } else if(typeof obj[property] === 'object' && !(obj[property] instanceof File)) {

        objectToFormData(obj[property], fd, formKey);

      }  else {
        // if it's a string or a File object
        if (obj[property] !== undefined)
        fd.append(formKey, stringToBoolean(obj[property]));
      }

    }
  }


  return fd;

};

const form = (params, files, _formData = null) => {
    var formData = objectToFormData(params, _formData)

    if (files) {
        for (var i in files) {
            if (Array.isArray(files[i])){
                for (var j in files[i]) {
                    if (files[i][j] instanceof File || files[i][j] instanceof Blob) {
                      formData.append(i + '[]', files[i][j]);
                    }
                    //  else {
                    //   const uri = files[i][j].uri?files[i][j].uri:files[i][j].path;
                    //   const image = {
                    //       uri: uri,
                    //       type: files[i][j].type?files[i][j].type:files[i][j].mime,
                    //       name: files[i][j].name ? files[i][j].name : fileNameAndExt(uri)
                    //   };
                    //
                    //   formData.append(i + '[]', image);
                    // }
                }
            } else {
                if (files[i] instanceof File || files[i] instanceof Blob) {
                  formData.append(i, files[i]);
                }
                // else {
                //   const uri = files[i].uri?files[i].uri:files[i].path;
                //   const image = {
                //       uri: uri,
                //       type: files[i].type?files[i].type:files[i].mime,
                //       name: files[i].name ? files[i].name : fileNameAndExt(uri)
                //   };
                //
                //   formData.append(i, image);
                // }
            }
        }
    }

    formData = modifier(formData, params);

    return formData;
}

const stringToBoolean = (value) => {

  if (value === true) {
    return 1
  }

  if (value === false) {
    return 0
  }

  return value
}

export const getToken = () => {
    return localStorage.getItem('tokenKey');
}

const getHeader = (token = null, lang) => {
    token = token?token:getToken();
    lang = lang?lang:document.documentElement.lang

    if (!token) {
        return {'Accept-Language': lang};
    }

    return {'Authorization': 'Bearer ' + token, 'Accept-Language': lang};
}

export const getLink = () => {
  return {
    url: scheme+baseDomain,
    baseUrl: baseUrl,
    headers: getHeader()
  }
}

const api =  {
     swrFetcher: ({url, params = {}, method = 'GET', options}) => {
         options = {...{showError: true}, ...options}

         const cancelSource = CancelToken.source();
         const cancelToken = cancelSource.token
         const {showError} = options

         return requester(Object.assign({
             method,
             url,
             headers: getHeader(null, params.locale),
             cancelToken
         }, method == 'GET'?{params: params}:{data:params})).then(r=>r.data)
     },
     request: (url, callback = (r: any, s: boolean)=>{}, params = {}, method = 'GET', options = {}) => {
         options = {...{showError: method!='GET', showSuccess: false}, ...options}

         const cancelSource = CancelToken.source();
         const cancelToken = cancelSource.token
         const {showError} = options

         requester(Object.assign({
             method,
             url,
             headers: getHeader(options.bearerToken),
             cancelToken
         }, method == 'GET'?{params: params}:{data:params})).then((response)=>{
             callback(response.data, true);
             if (options.showSuccess) {
               toast.success(response.data.message?response.data.message:(method=='DELETE'?'Data removed !':'Data saved !'))
             }
         }).catch((error) => {
             if (!error.response) {
                 return;
             }

             if (showError) {
                 showCommonError(error);
             }

             callback(error, false)
         });

         return cancelSource
     },
     showCommonError: showCommonError,
     form: form,
     url: {
         baseDomain,
         base: baseUrl,
         baseApi: baseApiUrl,
         ws: wsScheme + baseDomain,
         admin: baseAdminUrl,
         staticAsset: staticAssetUrl
     },
     getToken,
     applyLanguages: (fields, data) => {
       for (var k in data) {
            if (fields.indexOf(k) > -1 && typeof data[k] == 'string') {
              data[k] = {
                th: data[k],
                en: data[k]
              }
            }
        }

        return data
     }
}

function modifier(formData, params) {
    //append something

    return formData;
}

// This is a SWR middleware for keeping the data even if key changes.
export function laggySwr(useSWRNext) {
  return (key, fetcher, config) => {
    // Use a ref to store previous returned data.
    const laggyDataRef = useRef()

    // Actual SWR hook.
    const swr = useSWRNext(key, fetcher, config)

    useEffect(() => {
      // Update ref if data is not undefined.
      if (swr.data !== undefined) {
        laggyDataRef.current = swr.data
      }
    }, [swr.data])

    // Expose a method to clear the laggy data, if any.
    const resetLaggy = useCallback(() => {
      laggyDataRef.current = undefined
    }, [])

    // Fallback to previous data if the current data is undefined.
    const dataOrLaggyData = swr.data === undefined ? laggyDataRef.current : swr.data

    // Is it showing previous data?
    const isLagging = swr.data === undefined && laggyDataRef.current !== undefined

    // Also add a `isLagging` field to SWR.
    return Object.assign({}, swr, {
      data: dataOrLaggyData,
      isLagging,
      resetLaggy,
    })
  }
}

export function getQueryParams(search, raw = false) {
  if (!raw) {
    return Object.fromEntries(new URLSearchParams(search))
  }

  let params = {}

  search.substr(1).split('&').reduce((a, b) => {
    let [key, val] = b.split('=');
    a[key] = val;
    return a;
  }, params)

  return params
}

export default api;
