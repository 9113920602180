import React, {useEffect, useState} from 'react'
import { Container, Row, Col, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faArrowRight, faUserGroup, faToilet, faStar, faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { Scrollbars } from 'react-custom-scrollbars-2';
import {Pagination} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import  { useNavigate, NavLink } from 'react-router-dom'
import { numberFormat, classNames } from '../../utils/Functions'
import api from '../../utils/Api'
import {AccommodationImage} from './Accommodation'
import LessorFeedModal from './feed/LessorFeedModal'
import __coverImageMock from '../../assets/images/mock/accommodation-cover.png'
import locationFeed from '../../assets/images/mock/location-feed.png'
import duckIcon from '../../assets/images/duck.png'
import moment from 'moment'

function LessorItem(props: {
  item: any,
  className?: string
}) {
  const {item, className} = props
  const navigate = useNavigate()

  return (
    <div onClick={(e)=>{
      //navigate(`/accommodation/${item.id}`, {state: new Date})
    }} className={"rounded feed__item"+(className?` ${className}`:'')}>
      <div className="row-edge-center p-2">
        <div className="row-vcenter">
          <div className="feed__profile me-2" />
          <strong>{item.user.name}</strong>
        </div>
        <div className="row-vcenter">
          <span className="text-secondary">{moment(item.createdAt).format('DD/MM/YYYY')}</span>
        </div>
      </div>
      <AccommodationImage item={item} />
      <p className="p-3">{item.content}</p>
    </div>
  )
}

function RenterItem(props: {
  item: any,
  className?: string
}) {
  const {item, className} = props
  const navigate = useNavigate()

  return (
    <div onClick={(e)=>{
      //navigate(`/accommodation/${item.id}`, {state: new Date})
    }} className={"rounded feed__item"+(className?` ${className}`:'')}>
      <div className="row-edge-center p-2">
        <div className="row-vcenter">
          <div className="feed__profile me-2" />
          <strong>{item.user.name}</strong>
        </div>
        <div className="row-vcenter">
          <span className="text-secondary">{moment(item.createdAt).format('DD/MM/YYYY')}</span>
        </div>
      </div>
      <AccommodationImage item={{gallery: [{uri: locationFeed}]}} />
      <div className="px-3 pt-3">
        <div className="row-edge-center text-info">
          <div className="row-vcenter">
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <span className="ms-2">{item.location?.name}</span>
          </div>
          <span>ไม่เกิน {numberFormat(item.maxPrice)} บาท/คืน</span>
        </div>
        <Row className="mt-2">
          <Col xs={12} md={5} lg={12} xl={5} className="row-edge-center">
            <div className="feed__people-number rounded"><small>ผู้ใหญ่:</small><span className={classNames({
                  "ms-1": true,
                  "text-primary": !!item.people,
                  "text-muted": !item.people
              })}>{item.people}</span></div>
            <div className="feed__people-number rounded"><small>เด็ก:</small><span className={classNames({
                  "ms-1": true,
                  "text-primary": !!item.children,
                  "text-muted": !item.children
              })}>{item.children || 0}</span></div>
            <div className="feed__people-number rounded"><small>สัตว์เลี้ยง:</small><span className={classNames({
                  "ms-1": true,
                  "text-primary": !!item.pets,
                  "text-muted": !item.pets
              })}>{item.pets || 0}</span></div>
          </Col>
          <Col xs={12} md={7} lg={12} xl={7} className="mt-3 mt-md-0 mt-lg-3">
          <div className="row-edge-center rounded feed__check-time p-2">
            <div className="">
              <div className="text-secondary">
                เช็คอิน
              </div>
              <div className="mt-2">
                { moment(item.startDate).format('LL')}
              </div>
            </div>
            <div className="px-3 fs-4">
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
            <div className="">
              <div className="text-secondary">
                เช็คเอาท์
              </div>
              <div className="mt-2">
                {moment(item.endDate).format('LL')}
              </div>
            </div>
          </div>
          </Col>
        </Row>
        <hr />
      </div>
      <div className="px-3 pb-3">
        <p className="feed__bg p-2 rounded">{item.content}</p>
      </div>
    </div>
  )
}

export function LessorFeed() {
  const [paginate, setPaginate] = useState({data: [], page: 1, meta: {total: null}})
  const [showModal, setShowModal] = useState(false)
  const u = localStorage.getItem('userKey')

  const fetch = (params = {}) => {
    api.request('post', (r: any)=>{
      setPaginate(r)
    }, params)
  }
  useEffect(()=>{
    fetch()
  }, [])

  return (
    <Row>
      <Col xs={12} className="mb-3 row-vcenter">
        <h3>ประกาศหาผู้เช่า</h3>
        { u &&
        <Button onClick={()=>setShowModal(true)} className="ms-3">
          <FontAwesomeIcon icon={faCirclePlus} />{" "}สร้างประกาศ
        </Button>
        }
        <LessorFeedModal
          show={showModal}
          onHide={()=>setShowModal(false)}
          onSave={()=>{
            setShowModal(false)
            fetch()
          }}
        />
      </Col>
      {
        paginate.data.map((item, index)=>(
          <Col xs={12} lg={6}><LessorItem item={item} key={index} /></Col>
        ))
      }
    </Row>
  )
}

export function RenterFeed() {
  const [paginate, setPaginate] = useState({data: [], page: 1, meta: {total: null}})

  const fetch = (params = {}) => {
    api.request('announcement', (r: any)=>{
      setPaginate(r)
    }, params)
  }
  useEffect(()=>{
    fetch()
  }, [])

  return (
    <Row>
      <Col xs={12} className="mb-3">
        <h3>ประกาศหาที่พัก</h3>
      </Col>
      {
        paginate.data.map((item, index)=>(
          <Col xs={12} lg={6}><RenterItem item={item} key={index} /></Col>
        ))
      }
    </Row>
  )
}
