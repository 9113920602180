import React, { useEffect, useState, useRef } from 'react'
import { Container, Row, Col, Button, Badge, Card, Nav } from 'react-bootstrap';
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import {Layout, NoResult} from '../components'
import {AccommodationItem} from '../components/app/Accommodation'
import {CouponItem} from '../components/app/Coupon'
import api, {getToken} from '../utils/Api'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClockRotateLeft, faCalendarAlt, faSearch } from '@fortawesome/free-solid-svg-icons'
import { ChatList, MessageList, Input, Button as SendButton } from "react-chat-elements"
import moment from 'moment'
import avatar from '../assets/images/avatar.png'
import {io} from 'socket.io-client'

let clearRef = () => {};

export default function Chat() {
  const {id} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [paginate, setPaginate] = useState({data: [], page: 1, meta: {total: null}})
  const [messages, setMessages] = useState<any>([])
  const [messageMeta, setMessageMeta] = useState<any>({})
  const u = localStorage.getItem('userKey')
  const [text, setText] = useState('')
  const [user, setUser] = useState(u?JSON.parse(u):null)
  const ws = useRef<any>()
  const [sending, setSending] = useState(false)
  const inputReferance = React.useRef();
  const loadingMore = React.useRef(false);

  const fetch = (params = {}) => {
    api.request('chat-room', (r: any)=>{
      setPaginate(r)
    }, params)
  }

  useEffect(()=>{
    fetch()
    return ()=>{
      closeWs()
    }
  }, [])

  const connectWs = () => {

    if (ws.current || !user) {
        return
    }

    ws.current = io(api.url.ws, {
      auth: {token: getToken()},
      query: {type: 'badge'},
      transports: ['websocket']
    })

    ws.current.on('connect', ()=>{
      ws.current.emit('joinChat', id)
    })

    ws.current.on('disconnect', ()=>{
      ws.current.emit('leaveChat', id)
    })

    ws.current.on('newMessage', (m: any) => {
      if (m.userId === user.id) return
      setMessages([...messages, m])
      ws.current.emit('readMessages', id)
    })

    ws.current.on('readMessages', (userId: number) => {
       if (user.id === userId) return
      setMessages((_m: any)=>{
        const m = [..._m]

        for (let i in m) {
          m[i] = {...m[i], status: 'read'}
        }

        return m
      })
    })
  }

  const closeWs = () => {
    if (ws.current) {
      ws.current.disconnect()
      ws.current = null
    }
  }

  const fetchMessages = (p = {}, prepend = false) => {
    api.request('message', ({data, meta})=>{
      setMessageMeta(meta)
      const _m = prepend ? [...data.reverse(), ...messages] : data.reverse()
      setMessages(_m)
      loadingMore.current = false
      ws.current.emit('readMessages', id)
      if (!prepend) {
          setTimeout(() => scrollToBottom(), 0)
      }
    }, {chatRoomId: id, ...p})
  }

  useEffect(()=>{
    if (!id) return
    closeWs()
    connectWs()
    fetchMessages()
  }, [id])

  const convertMessage = (m: any)=>(
    {
      id: m.id,
      position: user.id === m.userId ? 'right':'left',
      text: m.body,
      title: '',
      focus: false,
      date: moment(m.createdAt).toDate(),
      titleColor: 'black',
      forwarded: false,
      replyButton: false,
      removeButton: false,
      status: !!m.readBy?'read':'sent',
      notch: true,
      retracted: false,
      type: m.type==='image'?'photo':'text',
      data: {
        uri: m.attachment?.uri,
        width: 300,
        height: 300
      },
    }
  )

  const scrollToBottom = () => {
  const mlistElement = document.getElementsByClassName('rce-mlist')[0];
    if (typeof mlistElement !== 'undefined') {
      mlistElement.scrollTop = mlistElement.scrollHeight;
    }
  };

  const { data, meta } = paginate

  return (
    <Layout>
      <Container className="my-3">
        <div className="container-with-sidemenu">
          <div className="container-with-sidemenu__menu" style={{minWidth: 300, maxWidth: 500}}>
          <ChatList
            id="1"
            lazyLoadingImage=""
            className='chat-list'
            onClick={(chatRoom)=>navigate('/chat/'+chatRoom.id)}
            dataSource={
              paginate.data.map(({me, id, participant}: any)=>({
                avatar: participant.user.profileImage?.uri || avatar,
                title: participant.user.name,
                subtitle: me.previewText,
                date: moment(me.latestSentAt).toDate(),
                unread: me.unread,
                id
              }))
            } />
          </div>
          <div className="container-with-sidemenu__body" style={{minHeight: 400, maxHeight: '70vh'}}>
          { !id ?
            <div className="column-center h-100">
              <NoResult
                title="ไม่ได้เลือกห้องแชท"
                content="กรุณาเลือกห้องแชท 💬"
               />
             </div>
             :
          <div className="d-flex flex-column" style={{height: '60vh'}}>
            <div className="overflow-auto" style={{flex: 1}}>
              <MessageList
                  referance={null}
                  className='message-list h-100'
                  lockable={false}
                  downButton
                  toBottomHeight={'100%'}
                  dataSource={
                    messages.map(convertMessage)
                  }
                  onScroll={(e: any)=>{
                    const { scrollHeight, scrollTop, clientHeight } = e.target;
                    if (scrollTop<scrollHeight*0.2 && !loadingMore.current) {
                      if (messageMeta.current_page >= messageMeta.last_page) {
                        return
                      }

                      loadingMore.current = true
                      fetchMessages({page: messageMeta.current_page+1}, true)
                    }
                  }}
                />
              </div>
              <Input
                referance={inputReferance}
   	            clear={(clear: any) => clearRef = clear}
                onChange={(e: any)=>{
                setText(e.target.value)
              }} multiline maxHeight={400}
                className="pt-3"
                placeholder="Type messages here.."
               rightButtons={<SendButton disabled={sending} onClick={()=>{
                 clearRef()
                 setSending(true)
                 api.request('message', (r, s)=>{
                   setSending(false)
                   if (!s) return
                   const m = [...messages]
                   m.push(r)
                   setMessages(m)
                 }, api.form({body: text, type: 'text', chatRoomId: id}), 'POST')
               }} text={sending?"Sending..":"Send"} />} />
          </div>
          }
          </div>
         </div>
      </Container>
    </Layout>
  )
}
