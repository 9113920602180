import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Badge, Card, Nav } from 'react-bootstrap';
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import {Layout, NoResult} from '../components'
import { AccommodationHorizontalItem } from '../components/app/Accommodation'
import Calendar from '../components/app/Calendar'
import api from '../utils/Api'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClockRotateLeft, faCalendarAlt, faSearch, faMapMarkerAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import {LessorWithMenu} from '../components/app/LessorMenu'
import moment from 'moment'
import { toast } from 'react-toastify';

export const getRemainingDays = (v: any) => {
  return (v?
      Math.max(0, Math.round(moment(v).diff(moment(), 'days', true))):'-') + ' วัน'
}

function MyAccommodationItem({item}: any) {
  const calendars = [...item.calendars,...item.user.forAllCalendars]
  const bookings = item.bookings
  const navigate = useNavigate()

  return (
    <div className="card-item">
      <AccommodationHorizontalItem item={item} />
      <div className="mx-2 pt-2 pb-2 row-edge-center">
        <span>อายุโพสต์คงเหลือ {getRemainingDays(item.publishExpiredAt)}</span>
        <span onClick={()=>{
          navigator.clipboard.writeText(`${item.lat}, ${item.lon}`)
          toast.success('คัดลอก​ Location แล้ว')
        }} className="text-primary cursor-pointer">
          <span className="text-decoration-underline me-2">คัดลอก Location</span>
          <FontAwesomeIcon icon={faMapMarkerAlt} />
        </span>
      </div>
      <div className="mx-2 pb-2">
        <Calendar
          item={item}
          calendars={calendars}
          bookings={bookings}
          enableDateChange={false}
        />
      </div>
      <div className="row-center pb-3 pt-2">
        <Button onClick={()=>navigate(`/my-accommodation/${item.id}#manage-date`)} variant="outline-primary"><FontAwesomeIcon icon={faCalendarAlt} />{" "}จัดการวันพัก</Button>
        <Button onClick={()=>navigate(`/my-accommodation/${item.id}`)} className="ms-3"><FontAwesomeIcon icon={faEdit} />{" "}จัดการบ้านพัก</Button>
      </div>
    </div>
  )
}

export default function MyAccommodation() {
  const routeParams = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [paginate, setPaginate] = useState({data: [], page: 1, meta: {total: null}})
  const u = localStorage.getItem('userKey')
  const [user, setUser] = useState(u?JSON.parse(u):null)

  const fetch = (params = {}) => {
    api.request('accommodation', (r: any)=>{
      setPaginate(r)
    }, {...params, userId: user?.id, withCalendars: true})
  }
  useEffect(()=>{
    fetch()
  }, [])

  const { data, meta } = paginate

  return (
    <Layout>
      <Container className="my-3">
        <LessorWithMenu>
        <h4 className="pb-3">บ้านพักของฉัน</h4>
          {
            paginate.meta.total === 0 &&
            <NoResult title="ไม่พบข้อมูล"
              content=""
            />
          }
          <Row>
            { paginate.data.map((item: any, index)=>(
              <Col lg={6} md={12} key={index} className="mb-3"><MyAccommodationItem onUpdate={()=>{
                fetch()
              }} item={item} /></Col>
            ))
            }
          </Row>
        </LessorWithMenu>
      </Container>
    </Layout>
  )
}
