import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { Container, Row, Col, Button, Badge, Modal } from 'react-bootstrap';
import {Layout} from '../components'
import {AccommodationItem, AccommodationBody, AccommodationImage} from '../components/app/Accommodation'
import {SearchFilter} from '../components/app/Home'
import Calendar from '../components/app/Calendar'
import RenterNumberModal from '../components/app/RenterNumber'
import { Swiper, SwiperSlide } from 'swiper/react';
import {Pagination, Navigation} from 'swiper';
import api from '../utils/Api'
import { numberFormat } from '../utils/Functions'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faArrowRight} from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import Sticky from 'react-stickynode';
import moment from 'moment'
import __coverImageMock from '../assets/images/mock/accommodation-cover.png'
import duck from '../assets/images/duck.png'

export default function Accommodation() {
  const  {id} = useParams()

  const [data, setData] = useState<any>(null)
  const [similarAccommodations, setSimilarAccommodations] = useState([])
  const [form, setForm] = useState<any>({})
  const [price, setPrice] = useState<any>({basePrice: 0, extraPeoplePrice: 0, extraPetPrice:0, totalPeople: 1})
  const [showRenterNumber, setShowRenterNumber] = useState(false)
  const [show, setShow] = useState<string|null>(null)
  const paymentMethod = useRef('')

  const fetch = () => {
    api.request('accommodation/' + id, (r: any)=>{
      setData(r)
    })
  }
  const fetchSimilar = () => {
    api.request('accommodation/'+id+'/similar', (res)=>{
      setSimilarAccommodations(res)
    })
  }
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDtUn1hJE8LHIyoCHUP-EcfP9POdqvYATA"
  })

  useEffect(()=>{
    window.scrollTo({top: 0, left: 0, behavior: 'auto'})
    fetch()
    fetchSimilar()
  }, [id])

  useEffect(()=>{
    let html = document.querySelector('html');
    if (html) {
      html.id = 'instant-scroll'
    }
  }, [])

  const images = useMemo(()=>{
    if (!data) return []
    if (data.coverImage) return [data.coverImage, ...(data.gallery||[])]

    return data.gallery || []
  }, [data])

  const getTagsByType = useCallback((_type: string) => {
    return data.tags?.filter(({type}: any)=>type === _type) || []
  }, [data])

  if (!data) {
    return (
      <Layout>
        <div className="row-center mt-5 px-3">
          <Spinner variant="secondary" />
        </div>
      </Layout>
    )
  }

  const maxOverPrice = Math.max(data.price.fridayPersonOverPrice||0,
      data.price.saturdayPersonOverPrice||0, data.sundayPersonOverPrice||0,
      data.price.holidayPersonOverPrice||0, data.price.vacationPersonOverPrice||0
  )
  const {basePrice, extraPeoplePrice, extraPetPrice, totalPeople} = price
  const nights = moment(form.endDate).diff(form.startDate, 'day')
  const totalPrice = basePrice + extraPeoplePrice + extraPetPrice

  return (
    <Layout>
      <Sticky>
      </Sticky>
     <Container className="py-3">
      <Row className="mb-3 d-none d-md-flex">
        <Col sm={12} md={6}>
          <div
            style={{backgroundImage: `url(${images[0]?.uri || __coverImageMock})`}}
            className="accommodation-item__image accommodation-item__image--standalone image-cover"
          />
        </Col>
        { images.length > 1 &&
        <Col className="d-flex flex-wrap" sm={12} md={6}>
          {
            images.slice(1, 4).map((image: any, index: number)=>(
            <div className="accommodation__more-image-items" key={index}>
              <div
                style={{backgroundImage: `url(${image.uri})`}}
                className="accommodation-item__image accommodation-item__image--standalone image-cover"
              />
            </div>
            ))
          }
        </Col>
        }
      </Row>
      <Row className="mb-3 d-flex d-md-none">
        <Col>
          <AccommodationImage item={data} />
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <AccommodationBody className="accommodation-item__body--standalone" item={data} />
          <div className="mt-3 accommodation__card accommodation__card--with-header">
            <div className="accommodation__floating-header">ข้อมูลเบื้องต้น</div>
            <div className="accommodation__info-row">
              <span>สไตล์ที่พัก</span>
              <span className="text-primary">{getTagsByType('style')
              .map(({name}: any)=>name)
              .join(',')}</span>
            </div>
            <div className="accommodation__info-row">
              <span>ราคา วันจันทร์-พฤหัสบดี</span>
              <span className="text-primary">{numberFormat(data.price.normalDayPrice)} บาท/ {data.price.normalDayPeople} คน</span>
            </div>
            <div className="accommodation__info-row">
              <span>ราคา วันศุกร์</span>
              <span className="text-primary">{numberFormat(data.price.fridayPrice||data.price.normalDayPrice)} บาท/ {data.price.normalDayPeople||data.price.fridayPeople} คน</span>
            </div>
            <div className="accommodation__info-row">
              <span>ราคา วันเสาร์</span>
              <span className="text-primary">{numberFormat(data.price.saturdayPrice||data.price.normalDayPrice)} บาท/ {data.price.saturdayPeople||data.price.normalDayPeople} คน</span>
            </div>
            <div className="accommodation__info-row">
              <span>ราคา อาทิตย์</span>
              <span className="text-primary">{numberFormat(data.price.sundayPrice||data.price.normalDayPrice)} บาท/ {data.price.sundayPeople||data.price.normalDayPeople} คน</span>
            </div>
            <div className="accommodation__info-row">
              <span>ราคา วันหยุดนักขัตฤกษ์</span>
              <span className="text-primary">{numberFormat(data.price.holidayPrice||data.price.normalDayPrice)} บาท/ {data.price.holidayPeople||data.price.normalDayPeople} คน</span>
            </div>
            <div className="accommodation__info-row">
              <span>ราคา วันหยุดยาว/ปีใหม่/สงกรานต์</span>
              <span className="text-primary">{numberFormat(data.price.vacationPrice||data.price.normalDayPrice)} บาท/ {data.price.vacationPeople||data.price.normalDayPeople} คน</span>
            </div>
            <div className="accommodation__info-row">
              <span>จำนวนคนเกินกำหนด</span>
              <span className="text-primary text-end">
                <div>เพิ่ม {numberFormat(data.price.normalDayPersonOverPrice)}
                {maxOverPrice>data.price.normalDayPersonOverPrice?`-${numberFormat(maxOverPrice)}`:``} บาท/คน</div>
                <div className="text-muted">
                  <small>(รวมไม่เกิน {data.price.normalDayMaxPeople} คน)</small>
                </div>
              </span>
            </div>
            { !!data.price.noChargeChildAge &&
            <div className="accommodation__info-row">
              <span>เด็กอายุไม่เกิน {data.price.noChargeChildAge} ขวบ</span>
              <span className="text-primary">
              พักฟรี {!!data.price.noChargeChildNumber && <small className="text-muted">(ไม่เกิน {data.price.noChargeChildNumber} คน)</small>}
              </span>
            </div>
            }
            <div className="accommodation__info-row">
              <span>สัตว์เลี้ยง (สุนัข/แมว)</span>
              <span className="text-primary">{numberFormat(data.price.petPrice)} บาท / ตัว</span>
            </div>
            <div className="accommodation__info-row">
              <span>มีค่าประกันของเสียหาย
              <div className="text-muted"><small>(จ่ายตอนเช็คอิน)</small></div>
              </span>
              <span className="text-primary text-end">
                {!data.price.insurancePrice?'ไม่มีค่าประกัน':numberFormat(data.price.insurancePrice)} บาท
                <div className="text-muted"><small>(รับคืนในวันเช็คเอาท์)</small></div>
              </span>
            </div>
            <div className="accommodation__info-row">
              <span>มีค่ามัดจำ
              <div className="text-muted"><small>(จ่ายวันจอง)</small></div>
              </span>
              <span className="text-primary text-end">
                {!data.price.downPayment?'ไม่มีค่าประกัน':numberFormat(data.price.downPayment)} บาท
                <div className="text-muted"><small>(ส่วนที่เหลือจ่ายวันเข้าพัก)</small></div>
              </span>
            </div>
          </div>
          <div className="mt-3 accommodation__card accommodation__card--with-header">
            <div className="accommodation__floating-header accommodation__floating-header--center">
              เลือกวันเช็คอินจากปฏิทิน
            </div>
            <Row>
              <Col className="row-center">
                <Calendar item={data}
                  onSubmit={(f: any)=>{
                    setForm({...form, ...f})
                    setShowRenterNumber(true)
                  }}
                  onCancel={()=>setForm({...form, startDate: null, endDate: null})}
                />
              </Col>
            </Row>
          </div>
          <div className="mt-3 accommodation__card accommodation__card--with-header">
            <div className="accommodation__floating-header">
              กิจกรรม
            </div>
            <Row>
              {getTagsByType('activity').map((tag: any)=>(
                <Col key={tag.id} className="row-vcenter" xs={6} lg={4}>
                  <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  <span className="ms-2 text-break">{tag.name}</span>
                </Col>
              ))
              }
            </Row>
          </div>
          <div className="mt-3 accommodation__card accommodation__card--with-header">
            <div className="accommodation__floating-header">
              เครื่องใช้ไฟฟ้า
            </div>
            <Row>
              {getTagsByType('electric').map((tag: any)=>(
                <Col key={tag.id} className="row-vcenter"  xs={6} lg={4}>
                  <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  <span className="ms-2 text-break">{tag.name}</span>
                </Col>
              ))
              }
            </Row>
          </div>
          <div className="mt-3 accommodation__card accommodation__card--with-header">
            <div className="accommodation__floating-header">
              สิ่งอำนวยความสะดวก อื่นๆ
            </div>
            <Row>
              {getTagsByType('facility').map((tag: any)=>(
                <Col key={tag.id} className="row-vcenter" xs={6} lg={4}>
                  <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  <span className="ms-2 text-break">{tag.name}</span>
                </Col>
              ))
              }
            </Row>
          </div>
        </Col>
        <Col md={6} sm={12}>
          <div className="accommodation__card mt-md-0 mt-3" id="summary">
            <div className="row-vcenter border-bottom pb-3">
              <div className="flex-fill">
                <div className="text-secondary">
                  เช็คอิน
                </div>
                <div className="fs-5 mt-2">
                  {!form.startDate ? 'โปรดเลือกจากปฏิทิน' : form?.startDate?.format('LL')}
                </div>
              </div>
              <div className="px-5 fs-4">
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
              <div className="flex-fill">
                <div className="text-secondary">
                  เช็คเอาท์
                </div>
                <div className="fs-5 mt-2">
                  {!form.endDate ? 'โปรดเลือกจากปฏิทิน' : form?.endDate?.format('LL')}
                </div>
              </div>
            </div>
            <div className="pt-3">
              <div className="accommodation__info-row accommodation__info-row--summarized">
                <span>ราคาที่พัก</span>
                <span>฿{numberFormat(basePrice)} ({nights} คืน)</span>
              </div>
              <div className="accommodation__info-row accommodation__info-row--summarized">
                <span>ส่วนเพิ่มจากจำนวนผู้เข้าพักเกินกำหนด</span>
                <span>{numberFormat(extraPeoplePrice)}</span>
              </div>
              <div className="accommodation__info-row accommodation__info-row--summarized">
                <span>ส่วนเพิ่มจากจำนวนสัตว์เลี้ยงที่เข้าพัก</span>
                <span>{numberFormat(extraPetPrice, false, '0')}</span>
              </div>
              <div className="accommodation__info-row accommodation__info-row--summarized">
                <span>ส่วนลดพิเศษ</span>
                <span>0</span>
              </div>
              <div className="accommodation__info-row accommodation__info-row--summarized">
                <span>โค้ดส่วนลด</span>
                <span>0</span>
              </div>
              <div className="accommodation__info-row accommodation__info-row--summarized">
                <span><strong>ราคาสุทธิ</strong>
                <div className="text-success"><small>ฟรีค่าธรรมเนียมและรวมภาษีแล้ว</small></div>
                </span>
                <span className="text-primary text-end">
                  ฿{numberFormat(totalPrice)}
                  <div className="text-danger"><small>เฉลี่ย ฿{totalPeople?numberFormat(totalPrice/totalPeople):'-'}/คน</small></div>
                </span>
              </div>
            </div>
            { form.startDate && form.endDate && form.people && totalPrice &&
            <div className="row-center mt-3">
              <Button onClick={()=>{
                api.request('booking', (r, s)=>{
                  if (!s) return
                  alert('กรุณารอเจ้าของที่พักยืนยันการจอง')
                  window.location.reload()

                }, api.form({
                  ...form,
                  accommodationId: data.id,
                  dateDiscountPrice: 0,
                  couponDiscountPrice: 0,
                  basePrice,
                  extraPeoplePrice,
                  extraPetPrice,
                  totalPrice
                }), 'POST')
              }}>
              จอง และ รอเจ้าของที่พักติดต่อกลับ
              </Button>
              <Button className="ms-2" variant="outline-primary" onClick={()=>{
                paymentMethod.current = 'in_app_credit'
                setShow('confirm')
              }}>
                จองโดยใช้ {numberFormat(totalPrice)} <img style={{width: 20, height: 20}} src={duck} />
              </Button>
            </div>
            }
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12}>
        { isLoaded &&
        <GoogleMap
            mapContainerStyle={{width: '100%', height: '30vw', maxHeight: 500, minHeight: 300}}
            center={{
              lat: 13,
              lng: 99
            }}
            zoom={10}
            // onLoad={onLoad}
            // onUnmount={onUnmount}
          >
        </GoogleMap>
        }
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12}>
          <h5><strong>บ้านพักอื่นที่ใกล้เคียง</strong></h5>
          <Swiper
          modules={[Navigation]}
          navigation
          slidesPerView={1}
          spaceBetween={20}
          breakpoints={{
            768: {
              slidesPerView: 2
            },
            992: {
              slidesPerView: 3
            },
            1400: {
              slidesPerView: 4
            }
          }}
        >
         { similarAccommodations.map((item: any, index: number)=>(
           <SwiperSlide key={index}>
             <AccommodationItem item={item} />
           </SwiperSlide>
         ))

        }
        </Swiper>
        </Col>
      </Row>
     </Container>
     <RenterNumberModal
      form={form}
      data={data}
      show={showRenterNumber}
      onHide={()=>setShowRenterNumber(false)}
      onSubmit={(f: any, p: any)=>{
        setForm({...form, ...f})
        setPrice(p)
        setShowRenterNumber(false)
        document.querySelector('#summary')?.scrollIntoView({
             behavior: 'smooth'
        });
      }}
     />
     <Modal show={show==='confirm'} onHide={()=>setShow(null)}>
       <Modal.Body>
         <h5>ยืนยันการจอง</h5>
         <ul className="mt-3 text-secondary">
           <li>กรุณาตรวจสอบวันว่าง และแจ้งใช้เครดิตในการจองกับเจ้าของที่พัก ก่อนทำการจองโดยใช้เครดิต</li>
         </ul>
         <div className="row-center mt-3">
           <Button onClick={()=>setShow(null)} variant="secondary">ยกเลิก</Button>
           <Button onClick={()=>{
             api.request('booking', (r, s)=>{
               if (!s) return
               alert('กรุณารอเจ้าของที่พักยืนยันการจอง')
               window.location.reload()

             }, api.form({
               ...form,
               accommodationId: data.id,
               dateDiscountPrice: 0,
               couponDiscountPrice: 0,
               basePrice,
               extraPeoplePrice,
               extraPetPrice,
               totalPrice,
               paymentMethod: paymentMethod.current
             }), 'POST')
           }} className="ms-2">ยืนยัน</Button>
         </div>
       </Modal.Body>
     </Modal>
    </Layout>
  )
}
