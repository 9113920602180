import {useState, useEffect} from 'react'
import { Container, Row, Col, Offcanvas, Accordion, Form, Nav } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClockRotateLeft, faCalendarAlt, faHome, faPercent,
  faCalendarCheck, faHouseCircleCheck } from '@fortawesome/free-solid-svg-icons'
import {faHeart as farHeart} from '@fortawesome/free-regular-svg-icons'
import _ from 'lodash'
import { useLocation, useParams, useNavigate } from 'react-router-dom'

export const LessorWithMenu = (props :any) => {
  const location = useLocation()
  const u = localStorage.getItem('userKey')
  const [user, setUser] = useState(u?JSON.parse(u):null)

  return (
  <div className="container-with-sidemenu">
    <div className="container-with-sidemenu__menu">
        <Nav defaultActiveKey={location.pathname} className="flex-column">
          <Nav.Link href="/booking/active">
            <FontAwesomeIcon className="me-3" icon={faCalendarAlt} />
            <span>การจองของฉัน</span>
          </Nav.Link>
          <Nav.Link href="/booking/history">
            <FontAwesomeIcon className="me-3" icon={faClockRotateLeft} />
            <span>ประวัติการจอง</span>
          </Nav.Link>
          <Nav.Link href="/coupon">
            <FontAwesomeIcon className="me-3" icon={faPercent} />
            <span>โค้ดส่วนลด</span>
          </Nav.Link>
          <Nav.Link href="/favorite">
            <FontAwesomeIcon className="me-3" icon={farHeart} />
            <span>รายการโปรด</span>
          </Nav.Link>
          { user.type === 'lessor' &&
          <>
            <Nav.Link href="/accommodation/create">
              <FontAwesomeIcon className="me-3" icon={faHome} />
              <span>เพิ่มบ้านพัก</span>
            </Nav.Link>
            <Nav.Link href="/my-accommodation">
              <FontAwesomeIcon className="me-3" icon={faHouseCircleCheck} />
              <span>บ้านพักของฉัน</span>
            </Nav.Link>
            <Nav.Link href={"/manage-booking"}>
              <FontAwesomeIcon className="me-3" icon={faCalendarCheck} />
              <span>จัดการการจอง</span>
            </Nav.Link>
          </>
          }
        </Nav>
      </div>
      <div className="container-with-sidemenu__body">
        {props.children}
      </div>
    </div>
  )
}
