import {useState, useEffect} from 'react'
import { Container, Row, Col, Button, Modal, Carousel, Form } from 'react-bootstrap';
import renter from '../../../assets/images/renter.png'
import lessor from '../../../assets/images/lessor.png'
import logo from '../../../assets/images/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import api from '../../../utils/Api'
import { dateRange } from '../../../utils/Functions'
import Counter from '../../../components/app/Counter'
import moment from 'moment'
import {GalleryUploader} from '../../index'

export default function LessorFeedModal(props: any) {
  const [form, setForm] = useState<any>({})
  const [images, setImages] = useState<any>(null)
  const [saving, setSaving] = useState(false)
  const f = {...props.form, ...form}

  useEffect(()=>{
    if (!props.show) {
      setForm({})
      setImages(null)
    }
  }, [props.show])

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={props.show}
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          สร้างประกาศหาผู้เช่า
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <Form.Group>
          <Form.Label>
            ข้อความ
          </Form.Label>
          <Form.Control
            as="textarea"
            value={form.content}
            onChange={(e)=>setForm({...form, content: e.target.value})}
          />
        </Form.Group>
        <hr />
        <Form.Group>
          <Form.Label>
            อัปโหลดรูป
          </Form.Label>
          <GalleryUploader onChange={(images)=> setImages(images.length?images:null)} />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={saving} onClick={()=>{
          setSaving(true)
          api.request('post', (r, s)=>{
            setSaving(false)
            if (!s) return
            props.onSave()
          }, api.form(form, images?{gallery: images}:null), 'POST', {showSuccess: true})

        }}>{saving?'กำลังอัปโหลด':'สร้าง'}</Button>
      </Modal.Footer>
    </Modal>
  )
}
