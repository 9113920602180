import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle} from '@fortawesome/free-solid-svg-icons'
import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons'

export default function CheckboxHightlight({checked, onCheck, label, className}: any) {

  return (
    <div className={className || ''}>
      <div onClick={onCheck}
      className={"d-inline-flex border bg-"+(checked?'primary-light':'white')+" border-"+ (checked?'primary':'secondary') +" rounded p-2 cursor-pointer"}>
        <FontAwesomeIcon className={"me-2 mt-1 text-" + (checked?'primary':'secondary')} size="lg" icon={checked?faCheckCircle:farCircle} />
        {label}
      </div>
    </div>
  )
}
