import React from 'react'
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faBed, faUserGroup, faToilet, faStar } from '@fortawesome/free-solid-svg-icons'
import { Scrollbars } from 'react-custom-scrollbars-2';
import {Pagination} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import  { useNavigate, NavLink } from 'react-router-dom'
import { numberFormat } from '../../utils/Functions'

import __coverImageMock from '../../assets/images/mock/accommodation-cover.png'
import duckIcon from '../../assets/images/duck.png'

function getPreviewNumber(min: number, max: number, suffix = '') {
  return max === min ? (min+suffix):`${min+suffix}+`
}

export function AccommodationBody(props: any) {
  const {item, className} = props

  return (
    <div className={"accommodation-item__body" + (className?` ${className}`:``)}>
      <strong>{item.name}</strong>
      <div className="row-vcenter text-muted">
        <FontAwesomeIcon icon={faMapMarkerAlt} />
        <span className="ms-2">{item.location?.name} {item.nearbyPlaces}</span>
      </div>
      <Scrollbars onClick={(e)=>{
        e.stopPropagation()
      }} autoHeight autoHide>
        <div className="mt-2 d-flex pb-2">
        { item.tags?.filter(({type}: any)=>type==='featured').map((tag: any, index: number)=>(
          <Badge className="me-2" key={index} pill bg="light" text="dark">
            {tag.name}
          </Badge>
        ))
        }
        </div>
      </Scrollbars>
      <div className="row-vcenter">
        <div className="accommodation-item__stats">
          <FontAwesomeIcon icon={faBed} />
          <span>ห้องนอน {getPreviewNumber(item.minRooms, item.maxRooms)}</span>
        </div>
        <div className="accommodation-item__stats">
          <FontAwesomeIcon icon={faToilet} />
          <span>ห้องน้ำ {getPreviewNumber(item.minBathrooms, item.maxBathrooms)}</span>
        </div>
        <div className="accommodation-item__stats">
          <FontAwesomeIcon icon={faUserGroup} />
          <span>{getPreviewNumber(item.price?.normalDayPeople, item.price?.normalDayMaxPeople, ' คน')}</span>
        </div>
      </div>
      <div className="row-edge-center accommodation-item__price">
        <span className="text-blue"><small className="text-muted">เริ่มต้น</small> ฿{numberFormat(item.price?.normalDayPrice||2000)}/คืน({item.price?.normalDayPeople || '1'} คน)</span>
        <span className="text-danger"><small>เฉลี่ย</small> ฿{numberFormat((item.price?.normalDayPrice||2000) / (item.price?.normalDayPeople || 1))}/คน</span>
      </div>
      <div className="row-edge-center my-2">
        <small><FontAwesomeIcon className="star" icon={faStar} />{" "}4.50 (ดีเยียม) 15 รีวิว</small>
        <small>ได้รับ <strong className="coin">250</strong> <img className="accommodation-item__duck" src={duckIcon} /></small>
      </div>
    </div>
  )
}

export function AccommodationImage(props: any) {
  const {item} = props

  return (
    <div className="accommodation-item__image-container position-relative">
      <Swiper
      modules={[Pagination]}
      slidesPerView={1}
      pagination={{ clickable: false }}
    >
     { (item.gallery||[item.coverImage]).map((img: any, index: number)=>(
       <SwiperSlide key={index}>
         <div
           style={{backgroundImage: `url(${img?.uri||__coverImageMock})`}}
           className="accommodation-item__image image-cover"
         />
       </SwiperSlide>
     ))

    }
    </Swiper>
    </div>
  )
}

export function AccommodationItem(props: {
  item: any,
  className?: string
}) {
  const {item, className} = props
  const navigate = useNavigate()

  return (
    <div onClick={(e)=>{
      navigate(`/accommodation/${item.id}`, {state: new Date})
    }} className={"cursor-pointer accommodation-item"+(className?` ${className}`:'')}>
      <AccommodationImage item={item} />
      <AccommodationBody item={item} />
    </div>
  )
}

export function AccommodationHorizontalItem (props: {
  item: any,
  className?: string
}) {
  const {item, className} = props
  const navigate = useNavigate()

    return (
      <div onClick={(e)=>{
        navigate(`/accommodation/${item.id}`, {state: new Date})
      }} className={"cursor-pointer accommodation-horizontal-item__container"}>
        <div style={{backgroundImage: `url(${item.coverImage?.uri})`}}
          className="image-cover accommodation-horizontal-item__cover">
        </div>
        <div className="p-2 flex-grow-1 border-bottom">
          <h5>{item.name}</h5>
          <div className="row-vcenter text-muted">
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <span className="ms-2">{item.location?.name} {item.nearbyPlaces}</span>
          </div>
          <small><FontAwesomeIcon className="star" icon={faStar} />{" "}4.50 (ดีเยียม) 15 รีวิว</small>
          <hr />
          <div className="row-edge-center">
            <span className="text-muted">เริ่มต้น</span>
            <span className="text-blue">฿{numberFormat(item.price?.normalDayPrice||2000)}/คืน({item.price?.normalDayPeople || '1'} คน)</span>
          </div>
          <div className="d-flex justify-content-end">
            <span className="text-danger"><small>เฉลี่ย</small> ฿{numberFormat((item.price?.normalDayPrice||2000) / (item.price?.normalDayPeople || 1))}/คน</span>
          </div>
        </div>
      </div>
    )
}
