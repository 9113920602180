import {useState, useEffect} from 'react'
import { Container, Row, Col, Button, Modal, Carousel, Form, Badge } from 'react-bootstrap';
import renter from '../../assets/images/renter.png'
import lessor from '../../assets/images/lessor.png'
import logo from '../../assets/images/logo.png'
import cover from '../../assets/images/mock/accommodation-cover.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import api from '../../utils/Api'
import { dateRange } from '../../utils/Functions'
import moment from 'moment'
import _ from 'lodash'
import {numberFormat} from '../../utils/Functions'
import Select from 'react-select';

const reasons = [
  'ขออภัย เพิ่งมีผู้เช่า walk in เข้ามา',
  'ขออภัย มีอุปกรณ์สำคัญของที่พักชำรุด',
  'ขออภัย ระบบไฟฟ้า/ประปา ขัดข้อง',
  'เหตุผล อื่นๆ'
]

export const bookingStates = [{
  value: 'requested',
  label: 'รอเจ้าของที่พักยืนยันการจอง',
  color: 'warning'
},{
  value: 'confirmed',
  label: 'การจองสำเร็จ',
  color: 'success'
},{
  value: 'paid',
  label: 'ชำระเงินแล้ว',
  color: 'success'
},{
  value: 'checked_in',
  label: 'เช็คอินแล้ว',
  color: 'primary'
},{
  value: 'checked_out',
  label: 'เช็คเอาท์แล้ว',
  color: 'primary'
},{
  value: 'renter_cancelled',
  label: 'ผู้เช่ายกเลิก',
  color: 'danger'
},{
  value: 'lessor_cancelled',
  label: 'เจ้าของที่พักยกเลิก',
  color: 'danger'
}]

export function BookingItem(props: any) {
  const {item, onUpdate, settingDefault} = props
  const {accommodation} = item
  const u = localStorage.getItem('userKey')
  const [user, setUser] = useState(u?JSON.parse(u):null)
  const [show, setShow] = useState<string|null>(null)
  const totalPeople = (item.people||0) + (item.children||0)
  const nights = moment(item.endDate).diff(item.startDate, 'day')
  const [reason, setReason] = useState('')
  const image = accommodation.gallery && accommodation.gallery[0] ? accommodation.gallery[0].uri :
                (accommodation.coverImage?.uri || cover)
  const state = _.find(bookingStates, ['value', item.state]) || bookingStates[0]
  const [isAnotherReason, setIsAnotherReason] = useState<boolean>(false)

  return (
    <div className="card-item">
      <Container>
        <Row>
          <Col lg={12} className="d-flex flex-column">
            <div className="booking__header">
              <div className="row-edge-center">
                <span className="fs-5">หมายเลขการจอง</span>
                <strong className="text-primary">#{item.id}</strong>
              </div>
              <div className="row-edge-center">
                <span className="text-muted">ผู้จอง:</span>
                <span className="text-muted">{item.user.name}</span>
              </div>
            </div>
            <div className="booking__body booking__body--border-right flex-grow-1">
              <div className="d-flex">
                <div
                  style={{backgroundImage: `url(${image})`}}
                  className="booking__image image-cover"
                />
                <div className="flex-grow-1 ms-2 me-2">
                  <div className="my-2">{accommodation.name}</div>
                  <hr className="my-0" />
                  <div className="row-edge-center mt-2">
                    <small className="text-muted">เริ่มต้น</small>
                    <small className="text-blue">฿{numberFormat(accommodation.price?.normalDayPrice||2000)}/คืน({accommodation.price?.normalDayPeople || '1'} คน)</small>
                  </div>
                  <div className="row-edge-center mt-1">
                    <small className="text-muted">ราคาเฉลี่ย</small>
                    <small className="text-danger">฿{numberFormat((accommodation.price?.normalDayPrice||2000) / (accommodation.price?.normalDayPeople || 1))}/คน</small>
                  </div>
                  <div className="row-edge-center mt-1">
                    <small className="text-muted">TeePak Credit</small>
                    <small>ได้รับ <span className="text-orange">{numberFormat(250)}</span></small>
                  </div>
               </div>
              </div>
              <hr className="dark mb-0" />
            </div>
          </Col>
          <Col lg={12}>
            <div className="booking__header">
              <div className="row-edge-center flex-wrap">
                <div>
                  <div className="text-muted fs-5"><span className="fs-6">วัน-เวลา ที่จอง:</span></div>
                  <div>{moment(item.createdAt).format('DD/MM/YYYY HH:mm')}</div>
                </div>
                <div>สถานะ: <Badge pill bg={state.color}>{state.label}{item.isRescheduled?<small> (เลื่อนวันเข้าพัก)</small>:''}</Badge></div>
              </div>
            </div>
            <div className="booking__body pt-3 px-3">
              <div className="row-vcenter border-bottom pb-3">
                <div className="flex-fill">
                  <small className="text-secondary">
                    เช็คอิน
                  </small>
                  <div className="mt-2">
                    {moment(item.startDate).format('LL')}
                  </div>
                </div>
                <div className="px-5 fs-4">
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
                <div className="flex-fill">
                  <small className="text-secondary">
                    เช็คเอาท์
                  </small>
                  <div className="mt-2">
                    {moment(item.endDate).format('LL')}
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <div className="row-edge-center mb-2">
                  <span>ราคาที่พัก</span>
                  <span>฿{numberFormat(item.basePrice)} ({nights} คืน)</span>
                </div>
                <div className="row-edge-center mb-2">
                  <span>ส่วนเพิ่มจากจำนวนผู้เข้าพักเกินกำหนด</span>
                  <span>{numberFormat(item.extraPeoplePrice)}</span>
                </div>
                <div className="row-edge-center mb-2">
                  <span>ส่วนเพิ่มจากจำนวนสัตว์เลี้ยงที่เข้าพัก</span>
                  <span>{numberFormat(item.extraPetPrice, false, '0')}</span>
                </div>
                <div className="row-edge-center mb-2">
                  <span>ส่วนลดพิเศษ</span>
                  <span>0</span>
                </div>
                <div className="row-edge-center mb-2">
                  <span>โค้ดส่วนลด</span>
                  <span>0</span>
                </div>
                <div className="row-edge-center mb-2">
                  <span><strong>ราคาสุทธิ</strong>
                  <div className="text-success"><small>ฟรีค่าธรรมเนียมและรวมภาษีแล้ว</small></div>
                  </span>
                  <span className="text-primary text-end">
                    ฿{numberFormat(item.totalPrice)}
                    <div className="text-danger"><small>เฉลี่ย ฿{totalPeople?numberFormat(item.totalPrice/totalPeople):'-'}/คน</small></div>
                  </span>
                </div>
              </div>
              { state.value === 'requested' && item.userId === user.id &&
                <div className="mx-3 text-center mb-3">
                  <hr className="dark" />
                  <span className="text-muted">
                    การจองจะสมบูรณ์ เมื่อเจ้าของที่พักยืนยันการจองแล้วเท่านั้น
                  </span>
                  <div><a onClick={()=>{
                    const c = window.confirm('คุณแน่ใจว่าจะยกเลิกการจอง?')
                    if (c) {
                      api.request('booking/'+item.id, ()=>{
                        if (onUpdate) {
                          onUpdate()
                        }
                      }, api.form({state: 'renter_cancelled'}), 'PATCH')
                    }
                  }} className="text-danger underline cursor-pointer">ยกเลิกการจอง</a></div>
                </div>
              }
              {
                state.value === 'confirmed' && !item.isRescheduled && item.userId === user.id &&
                moment().isBefore(moment(item.startDate), 'day') &&
                <div className="mx-3 text-center mb-3">
                  <hr className="dark" />
                  <div><a onClick={()=>{
                    setShow('reschedule')
                  }} className="text-warning underline cursor-pointer">เลื่อนวันเข้าพัก</a></div>
                </div>
              }
              {
                state.value === 'requested' && item.userId !== user.id &&
                <div className="d-flex mb-3">
                  <Button onClick={()=>setShow('deny')} variant="danger" className="flex-grow-1">ปฏิเสธการจอง</Button>
                  <Button onClick={()=>setShow('confirm')} className="flex-grow-1 ms-3">ยืนยันการจอง</Button>
                </div>
              }
            </div>
          </Col>
        </Row>
      </Container>
      <Modal show={show==='reschedule'} onHide={()=>setShow(null)}>
        <Modal.Body>
          <h5>เลื่อนวันเข้าพัก</h5>
          <ul className="mt-3 text-secondary">
            <li>หากคุณใช้เครดิตในการจอง ระบบจะไม่คืนเครดิตให้</li>
            <li>กรุณาติดต่อกับเจ้าของที่พัก เพื่อระบุวันที่อยากเปลี่ยน หลังกดปุ่มยืนยัน</li>
          </ul>
          <div className="row-center mt-3">
            <Button onClick={()=>setShow(null)} variant="secondary">ยกเลิก</Button>
            <Button onClick={()=>{
              api.request('booking/'+item.id + '/reschedule', ()=>{
                onUpdate()
                setShow(null)
              }, {}, 'PATCH')
            }} className="ms-2">ยืนยัน</Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show==='confirm'} onHide={()=>setShow(null)}>
        <Modal.Body>
          <h5>ยืนยันการจอง</h5>
          <ul className="mt-3 text-secondary">
            <li>ให้เครดิตผู้เช่า {settingDefault?.reviewFee}% ของค่าเช่าสุทธิ หากผู้เช่าทำการรีวิว</li>
            <li>ให้เครดิต Teepakhub {settingDefault?.creditFee}% ของค่าเช่าสุทธิเป็นค่าธรรมเนียม</li>
            <li>เครดิตค่าธรรมเนียม ไม่สามารถคืนได้ ดังนั้นก่อนกดยืนยันการจอง กรุณาตรวจสอบวันพักว่าว่างจริง</li>
          </ul>
          <div className="row-center mt-3">
            <Button onClick={()=>setShow(null)} variant="secondary">ยกเลิก</Button>
            <Button onClick={()=>{
              api.request('booking/'+item.id, ()=>{
                onUpdate()
                setShow(null)
              }, api.form({state: 'confirmed'}), 'PATCH')
            }} className="ms-2">ยืนยัน</Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show==='deny'} onHide={()=>setShow(null)}>
        <Modal.Body>
          <h5>ปฏิเสธการจอง</h5>
          <Select
            classNamePrefix="react-select"
            //styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            //menuPortalTarget={document.body}
            placeholder="เลือกเหตุผล"
            options={reasons.map((r)=>({label:r, value: r}))}
            name="locationId"
            onChange={({value}: any)=>{
              setReason(value)
              setIsAnotherReason(value === reasons[3])
            }}
            value={reason?reasons.indexOf(reason)===-1?{value: reasons[3], label: reasons[3]}:{value: reason, label: reason}:null}
          />
          { isAnotherReason &&
          <Form.Control
            placeholder="โปรดระบุเหตุผล อื่นๆ"
            className="mt-3"
            onChange={(e)=>setReason(e.target.value)}
           />
         }
          <div className="row-center mt-3">
            <Button onClick={()=>setShow(null)} variant="secondary">ยกเลิก</Button>
            <Button onClick={()=>{
              api.request('booking/'+item.id, ()=>{
                onUpdate()
                setShow(null)
              }, api.form({state: 'lessor_cancelled', cancelledReason: reason}), 'PATCH')
            }} className="ms-2">ยืนยัน</Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
