import {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCamera } from '@fortawesome/free-solid-svg-icons'

export default function GalleryUploader({onChange = (f: Array<any>)=>{}}) {
  const [images, setImages] = useState<any>([])
  const [displayImages, setDisplayImages] = useState<any>([])

  useEffect(()=>{
    const _img = []
    for (let i of images) {
      _img.push(URL.createObjectURL(i))
    }
    setDisplayImages(_img)
  }, [images])

  return (
    <div className="uploader">
      <div className="uploader__input">
        <FontAwesomeIcon icon={faCamera} />
        <span className="mt-2">เพิ่มรูปภาพ</span>
        <input type="file" multiple onChange={(e)=> {
          const f = Array.from(e.target.files || [])
          setImages(f)
          onChange(f)
        }} />
      </div>
      { displayImages.map((image: any, index: number)=>(
          <div key={index} style={{backgroundImage: `url(${image})`}}
          className="uploader__item image-cover ms-2" />
      ))
      }
    </div>
  )
}
